import {Select} from 'antd';
import {AbstractSelectProps} from 'antd/es/select';
import {memo, RefForwardingComponent, useEffect, useState} from 'react';
import {useSession} from '../../../contexts/Session';
import {typedForwardRef} from '../../../util/render';
import {Transporteur} from '../../Orders/data';

const {Option} = Select;

export interface TransporterSelectProps extends AbstractSelectProps {
  value?: Transporteur;
  onChange?: (value: Transporteur | undefined) => void;
  transporters?: Transporteur[];
}

const TransporterSelect: RefForwardingComponent<
  Select<string>,
  TransporterSelectProps
> = (props, ref) => {
  const {transporters, ...selectProps} = props;
  const {value: transporter, onChange} = selectProps;

  useEffect(() => {
    if (!transporters || transporters.length === 0) {
      return;
    }

    const currentTransporterID = transporter?.ID;
    const defaultTransporter: Transporteur | undefined =
      transporters.length === 1
        ? transporters[0]
        : transporters.find(({defaut}) => defaut);

    if (
      (currentTransporterID &&
        !transporters.find(({ID}) => ID === currentTransporterID)) ||
      (!currentTransporterID && defaultTransporter)
    ) {
      onChange?.(defaultTransporter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transporters, /*transporter,*/ onChange]);

  return (
    <Select<string>
      ref={ref}
      {...selectProps}
      value={transporter?.ID}
      onChange={(transporterID) =>
        onChange?.(transporters?.find(({ID}) => ID === transporterID))
      }
      disabled={(transporters?.length ?? 0) < 2}
    >
      {transporters?.map(({ID, libelle /* , delai, prix */}) => (
        <Option value={ID} key={ID}>
          {libelle}
          {/* {delai || prix ? ' (' : null}
          {delai ? `livraison en ${delai} jour${delai > 1 ? 's' : ''}` : null}
          {delai && prix ? ', ' : null}
          {prix ? (
            <span>
              à partir de&nbsp;
              <Currency price={prix} />
            </span>
          ) : null}
          {delai || prix ? ')' : null} */}
        </Option>
      ))}
    </Select>
  );
};

export default memo(typedForwardRef(TransporterSelect));
