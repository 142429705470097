import {Checkbox, Row} from 'antd';
import React, {FC, memo} from 'react';
import {useTerms} from '../../contexts/Quote';

const termsUrl = 'https://espace-pro.interson-protac.com/assets/cgv.pdf';

const Terms: FC = () => {
  const [terms, setTerms] = useTerms();

  return (
    <Row css={{marginBottom: 10, textAlign: 'left'}}>
      <Checkbox
        checked={terms}
        onChange={({target}) => setTerms(target.checked)}
      >
        En cliquant, vous acceptez les{' '}
        <a
          href={termsUrl}
          target="_blank"
          rel="noopener noreferrer"
          style={{fontWeight: 'bold', textDecoration: 'underline'}}
        >
          Conditions d&apos;utilisation
        </a>
      </Checkbox>
    </Row>
  );
};

export default memo(Terms);
