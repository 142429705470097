import {css} from '@emotion/core';
import {Col, Form} from 'antd';
import {FormComponentProps, FormLayout} from 'antd/lib/form/Form';
import {FC, memo} from 'react';
import {BaseQuoteData} from '../../contexts/Quote';
import {User, UserType} from '../../util/fetch-wakanda';
import {Adresse, Transporteur} from '../Orders/data';
import ShippingAddressSelect from '../Quote/patient/ShippingAddressSelect';
import TransporterSelect from '../Quote/patient/TransporterSelect';

type ShipmentFormData = Pick<BaseQuoteData, 'shippingAddress' | 'transporter'>;

const formCss = css`
  .ant-form-item {
    margin-bottom: 0;
  }

  label {
    color: #7d7b81;
  }
`;

interface ShipmentFormProps extends FormComponentProps<ShipmentFormData> {
  user: User;
  quote: BaseQuoteData;
  onSubmit?: (data: Partial<BaseQuoteData>) => void;
  layout?: FormLayout;
  addresses?: Adresse[];
  transporters?: Transporteur[];
}

const ShipmentForm: FC<ShipmentFormProps> = ({
  form,
  user,
  quote,
  onSubmit,
  layout,
  addresses = [],
  transporters = [],
}) => {
  const {getFieldDecorator, validateFieldsAndScroll} = form;

  const {clientId = user.ID} = quote;

  const handleSubmit = (): void => {
    validateFieldsAndScroll((err, values) => {
      if (err) {
        return;
      }

      onSubmit?.(values);
    });
  };

  return (
    <Form
      layout={layout}
      onSubmit={(event) => {
        event.preventDefault();
        handleSubmit();
      }}
      css={formCss}
    >
      <Col
        span={24}
        xl={12}
        css={{display: user.type === UserType.CLIENT ? 'none' : ''}}
      >
        <Form.Item label="Adresse de livraison">
          {getFieldDecorator('shippingAddress', {
            initialValue: quote?.shippingAddress,
          })(
            <ShippingAddressSelect
              placeholder="Adresse existante ou nouvelle"
              addresses={addresses}
              onChange={(shippingAddress) => {
                form.setFieldsValue({
                  shippingAddress,
                });
                handleSubmit();
              }}
            />,
          )}
        </Form.Item>
      </Col>
      <Col span={24} xl={12}>
        <Form.Item label="Transporteur">
          {getFieldDecorator('transporter', {
            initialValue: quote?.transporter,
          })(
            <TransporterSelect
              placeholder="Méthode de livraison"
              transporters={transporters}
              style={{minWidth: '200px'}}
              onChange={(transporter) => {
                form.setFieldsValue({
                  transporter,
                });
                handleSubmit();
              }}
            />,
          )}
        </Form.Item>
      </Col>
    </Form>
  );
};

export default memo(ShipmentForm);
