import {css, SerializedStyles} from '@emotion/core';
import {Button, Card, Icon, Radio, Upload} from 'antd';
import {RadioChangeEvent} from 'antd/lib/radio/interface';
import bytes from 'bytes';
import {FC, memo} from 'react';
import {
  QuoteTransfer,
  transferFabricationMethod,
  TransferMethod,
  transferMethodLabel,
  useQuote,
  useTransfer,
} from '../../contexts/Quote';

const printsCss = css`
  border: none;

  .ant-radio-group  {
    margin-bottom: 10px;
  }
  label.ant-radio-wrapper {
    margin: 4px 0;
  }


  .ant-card-head {
    padding-left: 0;
    .ant-card-head-title {
      padding-bottom: 5px;
    }

    .title {
      line-height: 32px;
      font-weight: bold;
      color: #78787d;
      text-transform: uppercase;
    }
  }

  .ant-card-body {
    border-top: solid 1px #78787d;
    background-color: #ececf1;
    color: #78787d;

    .ant-descriptions-item {
      .ant-descriptions-item-label {
        color: #78787d;
      }

      .ant-descriptions-item-content {
        font-weight: bold;
        color: #78787d;
      }
    }
  }
`;

const uploadListCss = (
  initialEditPrintsLength: number,
): SerializedStyles => css`
  .ant-upload-list {
    max-width: 250px;

    > div:nth-of-type(-n + ${initialEditPrintsLength})
      .ant-upload-list-item-card-actions {
      display: none;
    }
  }
`;

const transferMethodsValues = [TransferMethod.UPLOAD, TransferMethod.OCTOCLOUD, TransferMethod.PHSYCIAL];

const acceptedExtensions = ['.ois', '.easc', '.stl', '.estl', '.asc', '.eois'];

const Prints: FC = () => {
  const [{fabricationMethod, lines}] = useQuote();
  const [{method, prints}, setTransfer] = useTransfer();

  const hasEmpreintePhysique = lines.find((line) =>
    line.articles?.find((article) => article.empreintePhysique),
  );

  const handleMethodChange = ({
    target: {value: method},
  }: RadioChangeEvent): void => {
    const _transfer: Partial<QuoteTransfer> = {method};
    if (method !== TransferMethod.UPLOAD) {
      Object.assign(_transfer, {
        prints: [],
        _antPrints: [],
      });
    }

    setTransfer(_transfer);
  };

  const totalFileSize = prints.reduce(
    (_sum, {originFileObj: file}) => _sum + (file?.size || 0),
    0,
  );

  const initialEditPrints = prints.filter(({uid}) => uid.startsWith('-'));

  const availableTransferMethods = hasEmpreintePhysique
    ? [TransferMethod.PHSYCIAL]
    : fabricationMethod
    ? transferMethodsValues.filter(
        (value) => transferFabricationMethod[value] === fabricationMethod,
      )
    : transferMethodsValues;

  if (!availableTransferMethods.includes(method)) {
    setTransfer({method: availableTransferMethods[0]});
  }

  const handleFileChange: Upload['onChange'] = ({fileList: files}): void =>
    setTransfer({
      prints: files.filter(({name}) => {
        const extension = name.toLocaleLowerCase().match(/\.\w+$/);
        return extension && acceptedExtensions.includes(extension[0]);
      }),
    });

  return (
    <div>
      <Card
        css={printsCss}
        title={
          <div className="title">
            Transfert empreintes{' '}
            {method === TransferMethod.UPLOAD ? (
              <span style={{color: '#dd3214'}}>*</span>
            ) : null}
          </div>
        }
      >
        {transferMethodsValues.length < 2 ? (
          <p>{transferMethodLabel[method]}</p>
        ) : (
          <Radio.Group
            disabled={availableTransferMethods.length < 2}
            value={method}
            onChange={handleMethodChange}
            css={css`
              * {
                white-space: normal;
              }
            `}
          >
            {transferMethodsValues.map((method) => (
              <Radio
                value={method}
                key={method}
                disabled={!availableTransferMethods.includes(method)}
              >
                {transferMethodLabel[method]}
              </Radio>
            ))}
          </Radio.Group>
        )}
        {method === TransferMethod.UPLOAD ? (
          <Upload
            beforeUpload={() => false}
            onChange={handleFileChange}
            fileList={prints}
            multiple={true}
            css={uploadListCss(initialEditPrints.length)}
            accept={acceptedExtensions.join(',')}
          >
            <Button css={{marginTop: 5}} type="primary" ghost>
              <Icon type="upload" />{' '}
              <b style={{textTransform: 'uppercase'}}>Ajouter des fichiers</b>
            </Button>
            {totalFileSize ? (
              <span css={{marginLeft: 5}}>
                (Taille totale: {bytes(totalFileSize)})
              </span>
            ) : null}
          </Upload>
        ) : null}
      </Card>
    </div>
  );
};

export default memo(Prints);
