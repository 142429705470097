import {Checkbox, Descriptions, Typography} from 'antd';
import React, {FC, memo, ReactElement} from 'react';
import {useQuote} from '../../contexts/Quote';

const {Text} = Typography;

export interface OrderDescriptionProps {
  descriptionSpan?: number;
  title?: string | ReactElement;
}

const isoDateToFrench = (date: string): string => date.replace(/(\d+)-(\d+)-(\d+)/, '$3/$2/$1');

const OrderDescription: FC<OrderDescriptionProps> = ({
  descriptionSpan = 1,
  title = 'Informations commande',
}) => {
  const [quote] = useQuote();

  return (
    <Descriptions title={title} layout="horizontal" size="small">
      {quote?.client ? (
        <Descriptions.Item label="Client" span={descriptionSpan}>
          {quote.client.substr(0, quote.client.indexOf('(') - 1)}
        </Descriptions.Item>
      ) : null}
      {quote?.boxNumber ? (
        <Descriptions.Item label="N° boîte" span={descriptionSpan}>
          {quote.boxNumber}
        </Descriptions.Item>
      ) : null}
      {quote?.shippingDate ? (
        <Descriptions.Item label="Départ société" span={descriptionSpan}>
          {isoDateToFrench(quote.shippingDate)}
        </Descriptions.Item>
      ) : null}
      {quote?.waiting ? (
        <Descriptions.Item label="En attente" span={descriptionSpan}>
          <Text type="warning">{quote.waiting}</Text>
        </Descriptions.Item>
      ) : null}
      {quote?.warranty ? (
        <Descriptions.Item label="Sous garantie" span={descriptionSpan}>
          <Checkbox checked={true} disabled={true} />
        </Descriptions.Item>
      ) : null}
    </Descriptions>
  );
};

export default memo(OrderDescription);
