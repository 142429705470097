import {Button, Form, Input} from 'antd';
import {FormComponentProps} from 'antd/lib/form/Form';
import React, {FC, FormEventHandler, memo} from 'react';
import {Redirect} from 'react-router-dom';
import {useUser} from '../../contexts/Session';

export interface AccountFormData {
  email: string;
  contact: string;
  // username: string;
  // title: string;
  // lastName: string;
  // phoneLandLine: string;
  // phoneMobile: string;
  currentPassword?: string;
  newPassword?: string;
  newPasswordConfirm?: string;
}

export type AccountFormSubmitCallback = (data: AccountFormData) => void;

export interface AccountFormProps extends FormComponentProps<AccountFormData> {
  onSubmit: AccountFormSubmitCallback;
}

const AccountForm: FC<AccountFormProps> = ({form, onSubmit}) => {
  const user = useUser();
  if (!user) {
    return <Redirect to="/login" push={true} />;
  }

  const {getFieldDecorator, validateFieldsAndScroll} = form;

  const handleSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    validateFieldsAndScroll((err, values) => !err && onSubmit(values));
  };

  const values = form.getFieldsValue() as Partial<AccountFormData>;

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Item label="Nom d'utilisateur">
        {getFieldDecorator('email', {
          initialValue: user.name,
          rules: [
            {
              required: true,
              message: "Veuillez entrer votre nom d'utilisateur",
            },
          ],
        })(<Input />)}
      </Form.Item>
      <Form.Item label="Contact">
        {getFieldDecorator('contact', {
          initialValue: user.contact,
        })(<Input type="text" />)}
      </Form.Item>
      {/*<Form.Item label="Civilité">
        {getFieldDecorator('title')(
          <Select>
            <Option value="Madame">Madame</Option>
            <Option value="Mademoiselle">Mademoiselle</Option>
            <Option value="Monsieur">Monsieur</Option>
          </Select>,
        )}
      </Form.Item>
      <Form.Item label="Nom">
        {getFieldDecorator('lastName', {initialValue: user.fullName})(
          <Input />,
        )}
      </Form.Item>
      <Form.Item label="Téléphone">
        {getFieldDecorator('phoneLandLine')(<Input type="tel" />)}
      </Form.Item>
      <Form.Item label="Téléphone mobile">
        {getFieldDecorator('phoneMobile')(<Input type="tel" />)}
      </Form.Item>*/}
      <Form.Item label="Mot de passe actuel">
        {getFieldDecorator('currentPassword', {
          rules: [
            {
              required: !!values.newPassword,
              message:
                "Veuillez entrer votre mot de passe actuel afin d'en définir un nouveau",
            },
          ],
        })(<Input type="password" />)}
      </Form.Item>
      <Form.Item label="Nouveau mot de passe">
        {getFieldDecorator('newPassword', {
          rules: [
            {
              min: 5,
              message: 'Votre mot de passe doit contenir au moins 5 caractères',
            },
          ],
        })(<Input type="password" />)}
      </Form.Item>
      <Form.Item label="Confirmation du nouveau mot de passe">
        {getFieldDecorator('newPasswordConfirm', {
          rules: [
            {
              validator: (rule, value) => value === values.newPassword,
              message: 'Merci de resaisir le même mot de passe',
            },
          ],
        })(<Input type="password" />)}
      </Form.Item>
      <Button type="primary" htmlType="submit" icon="save">
        Valider
      </Button>
    </Form>
  );
};

export default memo(AccountForm);
