import {Button, Modal, Tooltip} from 'antd';
import jsPDF from 'jspdf';
import React, {FC, Fragment, memo, useState} from 'react';
import {useSession} from '../../contexts/Session';
import {WakandaService} from '../../util/fetch-wakanda';
import {Order} from '../Orders/data';
import {Article} from './util';

export const loadLogo = (): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = '/logo_square.png';
    img.onload = () => {
      resolve(img);
    };
    img.onerror = () => {
      reject('pcq');
    };
  });
};

const generate = async (
  session: WakandaService,
  commande: Order,
): Promise<string> => {
  const img = await loadLogo();

  const doc = new jsPDF();

  doc.setFontSize(10);
  doc.setFont('helvetica', 'normal');
  doc.setTextColor('#000000');

  // logo
  doc.addImage(img, 'PNG', 10, 10, 30, 10);

  // title
  doc.setFont('helvetica', 'bold');
  doc.setFontSize(11);
  doc.text('CERTIFICAT', 115, 17, {align: 'center'});
  doc.setFont('helvetica', 'normal');
  doc.text('DECLARATION RELATIVE A UN DISPOSITIF MEDICAL SUR MESURE', 115, 25, {
    align: 'center',
  });
  doc.setFontSize(11);
  doc.text(
    'Conformément à l’annexe VIII de la directive 93/42/CEE du 14 juin 1993 relatif aux dispositifs médicaux, et au décret N° 95-292 du 16 mars 1995, nous déclarons que le dispositif médical sur mesure suivant est conforme aux exigences essentielles de l’Annexe 1 de la directive susmentionnée qui sont applicables.',
    10,
    45,
    {
      maxWidth: 190,
    },
  );

  // client & delivery
  doc.setFontSize(10);
  doc.setFont('helvetica', 'bold');
  doc.text('Prescripteur :', 10, 70);
  doc.setFont('helvetica', 'normal');
  doc.text(commande.livr_raisonSociale || '', 10, 75);
  doc.text(commande.livr_adresse || '', 10, 80);
  doc.text(`${commande.livr_codePostal} ${commande.livr_ville}`, 10, 85);
  doc.text(`E-mail : ${commande.email}`, 10, 90);
  doc.text('N° OF :', 110, 70);
  doc.text('Patient :', 110, 75);
  doc.setFont('helvetica', 'bold');
  doc.text(commande.numeroBon, 130, 70);
  doc.text(commande.patient, 130, 75);

  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  doc.setLineDash();
  doc.rect(10, 105, 95, 60);
  doc.rect(105, 105, 95, 60);

  doc.setFontSize(16);
  doc.setFont('helvetica', 'bold');
  doc.text('OG', 15, 115);
  doc.text('OD', 110, 115);

  doc.setFontSize(14);
  doc.setFont('helvetica', 'normal');
  const articlesIds: string[] = [];
  ['OG', 'OD'].map((earStr: string): void => {
    const ear = earStr as 'OG' | 'OD';
    if (commande.details[ear]?.article) {
      articlesIds.push(commande.details[ear]?.article || '');
    }
    if (commande.details[ear]?.bague) {
      articlesIds.push(commande.details[ear]?.bague || '');
    }

    return undefined;
  });
  const articles =
    (await session.fetch<Article[]>('Articles', 'getArticlesFromIDs', {
      // eslint-disable-next-line @typescript-eslint/camelcase
      ids: articlesIds,
    })) || [];
  ['OG', 'OD'].map((earStr: string, index: number): void => {
    const ear = earStr as 'OG' | 'OD';
    const article = articles.find(
      ({ID}) => ID === commande.details[ear]?.article,
    );

    if (article) {
      doc.setFontSize(14);
      doc.text('1x ' + article.libelle, 15 + 95 * index, 125, {maxWidth: 90});
    }

    doc.setFontSize(12);
    doc.setFont('helvetica', 'normal');

    const bague = articles.find(({ID}) => ID === commande.details[ear]?.bague);

    if (bague) {
      doc.text('Bague : ' + bague.code, 15 + 95 * index, 140, {maxWidth: 90});
    }

    return undefined;
  });

  doc.setFontSize(10);
  doc.setFont('helvetica', 'normal');
  doc.text(
    'Tél: 00 33 (0) 466 80 22 89 • Fax: 00 33 (0) 466 80 22 81',
    105,
    270,
    {align: 'center'},
  );
  doc.text(
    "Fabrication d'embouts et protections • Fournitures pour prothèses auditives",
    105,
    275,
    {align: 'center'},
  );
  doc.text(
    "SAS INTERSON PROTAC au capital de 92 000 euros • Siège CS 30255 - 1 route d'Aubais F 30111 CONGENIES",
    105,
    280,
    {align: 'center'},
  );
  doc.text(
    'SIRET 38475070900022 • APE 3250 A • TVA INTRACOMMUNAUTAIRE FR57384750709',
    105,
    285,
    {align: 'center'},
  );

  return doc.output('bloburi');
};

interface Props {
  large?: boolean;
  disabled?: boolean;
  order: Partial<Order>;
}

const CertificateModal: FC<Props> = ({large, disabled, order}) => {
  const session = useSession();

  const [dataUri, setDataUri] = useState('');

  const hideModal = (): void => {
    setDataUri('');
  };

  const getPDF = async (): Promise<void> => {
    const dataUri = await generate(session, order as Order);

    setDataUri(dataUri);
  };

  return (
    <Fragment>
      <Tooltip
        title={!disabled ? 'Télécharger le certificat de conformité' : null}
      >
        <Button
          disabled={disabled}
          shape={large ? undefined : 'circle'}
          icon="safety-certificate"
          style={{margin: '0 8px'}}
          onClick={getPDF}
        >
          {large ? 'Certificat de conformité' : null}
        </Button>
      </Tooltip>
      <Modal
        width="90vw"
        visible={dataUri.length > 0}
        footer={[
          <a key="download" href={dataUri} download={`${order.patient}.pdf`}>
            <Button>Télécharger</Button>
          </a>,
          <Button
            key="close"
            type="primary"
            onClick={hideModal}
            style={{marginLeft: 8}}
          >
            Fermer
          </Button>,
        ]}
      >
        <div style={{height: '80vh'}}>
          {dataUri.length > 0 ? (
            <iframe
              title="PDF"
              src={`/pdf/web/viewer.html?file=${dataUri}`}
              width="100%"
              height="100%"
              style={{border: 0}}
            />
          ) : null}
        </div>
      </Modal>
    </Fragment>
  );
};

export default memo(CertificateModal);
