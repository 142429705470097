import {css} from '@emotion/core';
import {Button, Card, Col, Icon, Row, Tooltip} from 'antd';
import {darken} from 'polished';
import {ReactElement} from 'react';
import {
  BaseProduct,
  Product,
  useCurrentSubCategory,
} from '../../contexts/Quote';
import {colors, typedMemo} from '../../util/render';
import {DEPORTE_BRAND, PROTECTION_SHAPE} from '../Options/data';

const protectionCss = css`
  padding: 2px;
  height: 100%;
  padding-bottom: 35px;

  .button-container {
    position: absolute;
    bottom: -18px;
    width: 100%;
  }
  .ant-btn {
    margin: auto;
    font-weight: bold;
  }

  .ant-card {
    border: none;
    height: 100%;
    box-shadow: 3px 3px 10px 0px #ccc;
  }

  .ant-card-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 0;
    border: none;
    margin-bottom: 5px;

    .background {
      position: relative;
      height: 200px;
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: center;

      img {
        width: 100%;
        object-fit: contain;
        max-width: 175px;
        max-height: 200px;
        margin: auto;
      }

      .background-padding {
        flex: 1;
      }

      .background-hover {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 200px;
        opacity: 0.7;

        &:not(.transparent) {
          background-color: #404040;
        }
      }
    }

    .foreground {
      white-space: pre-wrap;
      padding: 0 10px;
      display: flex;
      justify-content: space-around;
      flex-direction: column;
    }

    .product-name {
      font-weight: bold;
      color: #cc1e26;
      text-align: center;
      font-size: 20px;
      min-height: 60px;
    }

    .description {
      font-size: 12px;
      color: #78787d;
      text-align: justify;
      padding: 0 5px 10px 5px;

      b {
        color: #4c4c4c;
      }
    }
  }
`;

const categoryCss = css`
  --selected-color: white;
  --hover-border-color: ${darken(0.3, 'white')};

  --selected-background-color: ${colors.primary};
  --selected-hover-border-color: ${darken(0.2, colors.primary)};

  --disabled-background-color: #e1e1e1;

  background-color: transparent;

  border: none;
  &.category .ant-card-body {
    max-height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 0;

    .background {
      max-height: 300px;
      img {
        width: 100%;
        object-fit: contain;
        padding: 0 5px;
        filter: grayscale() brightness(2);
        max-height: 300px;
      }
    }

    .foreground {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      max-height: 300px;
      margin: auto;
      text-align: center;
      font-size: 18px;
      white-space: pre-wrap;
      padding: 0 10px;
      display: flex;
      justify-content: space-around;
      flex-direction: column;

      @media (max-width: 400px) {
        font-size: 12px !important;
      }

      p {
        max-width: 300px;
        text-transform: uppercase;
        margin: auto;
        color: white;
      }
    }

    &:hover {
      .background img {
        filter: none;
      }

      .foreground p {
        color: white;
      }
    }
  }
  &.category.selected {
    .background img {
      filter: none;
    }

    .foreground p {
      color: white;
    }
  }

  &.product .ant-card-body {
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 10px 0;

    &:hover {
      .background .background-hover {
        background-color: #9e220f !important;
        opacity: 0.7;
      }
      .background img {
        filter: grayscale();
      }
    }

    .background {
      position: relative;
      height: 200px;
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: center;
      .background-padding {
        flex: 1;
      }

      img {
        flex: 0;
        max-width: 150px;
        object-fit: contain;
        margin: auto;
      }

      .background-hover {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.7;
        z-index: 2;

        &:not(.transparent) {
          background-color: #1e1e1e;
        }
      }
    }

    .foreground {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      text-align: center;
      font-size: 18px;
      white-space: pre-wrap;
      padding: 0 10px;
      z-index: 3;
      display: flex;
      justify-content: space-around;
      flex-direction: column;

      p {
        text-transform: uppercase;
        margin-bottom: 0;
        color: white;
      }
    }

    /* .foreground {
      color: #dd3214;
      text-decoration: underline;
      font-weight: bold;
      text-align: center;
    } */
  }

  &.product.is-subcategory {
    .ant-card-body {
      height: 200px;
      .background {
        height: 150px;
        width: 100%;
      }

      .foreground {
        width: 150px;
      }
    }
  }

  &:hover {
    box-shadow: none;
  }

  &.disabled {
    pointer-events: none;
    cursor: default;
    .ant-card-body {
      background-color: var(--disabled-background-color);
    }
  }
`;

export interface ProductSelectionProps<T extends BaseProduct> {
  readonly value?: T;
  readonly products: T[] | {[subCategory: string]: T[]};
  readonly onSelect?: (product: T) => void;
  readonly isCategory?: boolean;
  readonly cards?: boolean;
}

const isProduct = (product: BaseProduct): product is Product =>
  !!(product as Product).name;

export const physicalTransferShapes = [
  PROTECTION_SHAPE.PASSTOP_OS,
  PROTECTION_SHAPE.PIANISSIMO,
  PROTECTION_SHAPE.EARBACK_MUSIC,
  PROTECTION_SHAPE.PASSTOP_TIR_CHASSE,
  PROTECTION_SHAPE.PASSTOP_STOPGUN,
  PROTECTION_SHAPE.STOPGUN_FLEX,
  PROTECTION_SHAPE.STOPGUN_E,
  PROTECTION_SHAPE.EAR,
  PROTECTION_SHAPE.OREILLETTE,
  PROTECTION_SHAPE.PASSTOP_OS_CONQUE,
  PROTECTION_SHAPE.SECRETEAR,
];

function ProductSelection<T extends BaseProduct>({
  value,
  products,
  onSelect,
  isCategory = false,
  cards = false,
}: ProductSelectionProps<T>): ReactElement {
  const [subCategory] = useCurrentSubCategory();

  const isProtection = !Array.isArray(products) && subCategory;
  const filteredProducts =
    !Array.isArray(products) && subCategory
      ? products[subCategory] ?? products
      : products;

  const mappedProducts = Array.isArray(filteredProducts)
    ? {none: filteredProducts}
    : filteredProducts;
  const hasSubCategories = Object.keys(mappedProducts).length > 1;

  if (cards || isProtection) {
    return (
      <Row type="flex" gutter={10}>
        {Object.keys(mappedProducts).map((subCategory, subIndex) => (
          <Row
            type="flex"
            gutter={10}
            key={subIndex}
            style={{width: '100%', justifyContent: 'space-between'}}
          >
            {mappedProducts[subCategory].map((product, index) => (
              <Col span={12} xl={8} key={index}>
                <div css={protectionCss}>
                  <Card
                    className={[
                      isCategory ? 'category' : 'product',
                      value === product ? 'selected' : '',
                      product.disabled ? 'disabled' : '',
                      hasSubCategories ? 'is-subcategory' : '',
                    ]
                      .filter((className) => !!className)
                      .join(' ')}
                  >
                    <div className="background">
                      <span className="background-padding" />
                      <img alt={product.label} src={product.img} />
                      <span className="background-padding" />
                    </div>
                    <div className="foreground">
                      {isProtection ? (
                        <p
                          className="product-name"
                          css={product.subLabel ? {margin: 0} : {margin: '5px'}}
                        >
                          {Object.values(DEPORTE_BRAND).includes(
                            (product as any).name,
                          )
                            ? ''
                            : product.label}
                        </p>
                      ) : null}
                      {product.subLabel ? (
                        <p>
                          <em>{product.subLabel}</em>
                        </p>
                      ) : null}
                      <div className="description">{product.description}</div>
                      <div className="button-container">
                        <Button
                          type="primary"
                          onClick={() =>
                            !product.disabled && onSelect && onSelect(product)
                          }
                        >
                          Je commande
                        </Button>
                      </div>
                    </div>
                  </Card>
                </div>
              </Col>
            ))}
          </Row>
        ))}
      </Row>
    );
  }

  return (
    <Row type="flex" gutter={10}>
      <Col span={24}>
        {isCategory ? (
          <h2
            style={{
              textTransform: 'uppercase',
              color: '#78787d',
              textAlign: 'left',
              fontSize: '18px',
              fontWeight: 'bold',
              marginBottom: '30px',
            }}
          >
            Catégories d&apos;embouts
          </h2>
        ) : null}
      </Col>
      {Object.keys(mappedProducts).map((subCategory, subIndex) => (
        <div key={subIndex} style={{width: '100%'}}>
          {hasSubCategories ? (
            <h4
              style={{
                fontWeight: 'bold',
                textAlign: 'left',
                color: '#78787d',
                fontSize: '18px',
                marginTop: '50px',
              }}
            >
              {subCategory}
            </h4>
          ) : null}
          {mappedProducts[subCategory].map((product, index) => (
            <Col span={12} xl={8} key={index}>
              <Card
                hoverable
                css={categoryCss}
                className={[
                  isCategory ? 'category' : 'product',
                  value === product ? 'selected' : '',
                  product.disabled ? 'disabled' : '',
                  hasSubCategories ? 'is-subcategory' : '',
                ]
                  .filter((className) => !!className)
                  .join(' ')}
                onClick={() =>
                  !product.disabled && onSelect && onSelect(product)
                }
              >
                <div className="background">
                  <span className="background-padding" />
                  <img alt={product.label} src={product.img} />
                  <span className="background-padding" />
                  <div
                    className={[
                      'background-hover',
                      Object.values(DEPORTE_BRAND).includes(
                        (product as any).name,
                      )
                        ? 'transparent'
                        : '',
                    ].join(' ')}
                  />
                </div>
                <div className="foreground">
                  <p css={product.subLabel ? {margin: 0} : {margin: '5px'}}>
                    {Object.values(DEPORTE_BRAND).includes(
                      (product as any).name,
                    )
                      ? ''
                      : product.label}
                  </p>
                  {product.subLabel ? (
                    <p>
                      <em>{product.subLabel}</em>
                    </p>
                  ) : null}

                  {isProduct(product) &&
                  physicalTransferShapes.includes(
                    product.name as PROTECTION_SHAPE,
                  ) ? (
                    <Tooltip
                      placement="top"
                      overlayClassName="tooltip-red"
                      title="Envoi des empreintes physiques obligatoires pour ce produit"
                    >
                      <Icon
                        type="question-circle"
                        style={{marginLeft: '5px'}}
                      />
                    </Tooltip>
                  ) : null}
                </div>
              </Card>
            </Col>
          ))}
        </div>
      ))}
    </Row>
  );
}

export default typedMemo(ProductSelection);
