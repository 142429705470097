import {FC} from 'react';
import {useHistory} from 'react-router-dom';
import {useSession} from './contexts/Session';

const Logout: FC = () => {
  const session = useSession();
  const history = useHistory();

  (async () => {
    await session.logout();
    delete localStorage.intersonToken;
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    window.location = 'https://espace-pro.interson-protac.com';
  })();
  return null;
};

export default Logout;
