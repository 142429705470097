import {Button, Select} from 'antd';
import {SelectProps} from 'antd/lib/select';
import React, {FC, forwardRef, memo, useState} from 'react';
import countries from './countries.json';

const {Option, OptGroup} = Select;

export type CountrySelectProps = SelectProps<string>;
type IsoCode = keyof typeof countries;

const isoGroups: {[key: string]: IsoCode[]} = {
  frequent: ['FR', 'DZ', 'BE', 'LU', 'MA'],
  dom: ['GP', 'MQ', 'RE', 'GF', 'PF', 'NC', 'YT', 'MF', 'WF', 'BL', 'PM', 'TF'],
};

const [frequentCountries, domCountries] = [
  isoGroups.frequent,
  isoGroups.dom,
].map((list) => list.map((isoCode) => [isoCode, countries[isoCode]]));

const flatIsoGroups = Object.values(isoGroups).flat();
const otherCountries = Object.entries(countries).filter(
  ([code]) => !flatIsoGroups.includes(code as IsoCode),
);

const countryGroups = [
  {label: 'Pays fréquents', countries: frequentCountries},
  {label: "France d'Outre-Mer", countries: domCountries},
  {label: 'Autres pays', countries: otherCountries},
];

const __LOAD_MORE_VALUE = '__LOAD_MORE_VALUE';

const CountrySelect: FC<CountrySelectProps> = (props, ref) => {
  const [country, setCountry] = useState(props.value);
  const [loadAll, setLoadAll] = useState(false);

  const visibleCountryGroups = countryGroups.slice(
    0,
    countryGroups.length - (loadAll ? 0 : 1),
  );

  return (
    <Select<string>
      ref={ref}
      {...props}
      showSearch
      value={country}
      onChange={(country, option) => {
        if (country === __LOAD_MORE_VALUE) {
          setLoadAll(true);
          return;
        }

        setCountry(country);
        props.onChange?.(country, option);
      }}
    >
      {visibleCountryGroups.map(({label, countries}, index) => (
        <OptGroup label={label} key={index}>
          {countries.map(([code, name]) => (
            <Option value={code} key={code}>
              {name}
            </Option>
          ))}
        </OptGroup>
      ))}
      {!loadAll ? (
        <OptGroup label="Autres pays">
          <Option value={__LOAD_MORE_VALUE}>
            <Button
              type="link"
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                setLoadAll(true);
              }}
            >
              Montrer tous les pays
            </Button>
          </Option>
        </OptGroup>
      ) : null}
    </Select>
  );
};

export default memo(forwardRef(CountrySelect));
