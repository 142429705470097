import {css} from '@emotion/core';
import {Button, Col, Row, Tooltip} from 'antd';
import {FC, memo, ReactElement} from 'react';
import {Link, useHistory, useLocation} from 'react-router-dom';
import {
  useCurrentEars,
  useCurrentLine,
  useCurrentSelectedOptions,
  useCustomMarkFields,
  useLines,
} from '../../contexts/Quote';
import {getMissingGroups} from '../../util/render';
import {PROTECTION_SHAPE} from './data';
import {getSelectedEars, Group, SELECTED_EARS} from './static';

const formButtonsCss = css`
  .ant-btn {
    text-transform: uppercase;
  }
`;

export interface AddToCartButtonProps {
  groups: Group[];
  children?: ReactElement;
  parentPath: string;
}

const MissingGroupTooltip: FC<{
  groups: Group[];
  suffix?: string;
}> = ({groups, suffix = ''}) =>
  groups.length ? (
    <div>
      <p>
        Les options obligatoires suivantes n&apos;ont pas été renseignées
        <em> {suffix}</em> :
      </p>
      <ul>
        {groups.map(({label}, index) => (
          <li key={index}>{label}</li>
        ))}
      </ul>
    </div>
  ) : null;

const FormButtons: FC<AddToCartButtonProps> = ({
  groups,
  children,
  parentPath,
}) => {
  const [currentLine] = useCurrentLine();
  const [lines, setLines] = useLines();
  const [ears] = useCurrentEars();
  const [selectedOptions] = useCurrentSelectedOptions();
  const [customMarkFields] = useCustomMarkFields();

  const history = useHistory();
  const {pathname} = useLocation();

  const {
    missingGroups,
    emptyRequiredGroups,
    missingEarsRequiredGroups,
  } = getMissingGroups(ears, selectedOptions, groups);

  const selectedEars = getSelectedEars(ears);

  const hasMissingCustomMarkField =
    [PROTECTION_SHAPE.EAR, PROTECTION_SHAPE.OREILLETTE].includes(
      currentLine?.product?.name as PROTECTION_SHAPE,
    ) &&
    (!customMarkFields?.[0] || !customMarkFields?.[1]);
  const disabled =
    selectedEars === SELECTED_EARS.NONE ||
    missingGroups.length > 0 ||
    hasMissingCustomMarkField;

  const pathTokens = pathname.split('/');
  const selectedGroup =
    groups.find(({name}) => name === pathTokens[pathTokens.length - 1]) ||
    groups[0];
  const lastGroup = groups[groups.length - 1];
  const nextGroup = groups[groups.indexOf(selectedGroup) + 1];

  if (currentLine.valid !== !disabled) {
    currentLine.valid = !disabled;
    // setLines([...lines]);
  }

  return (
    <Row
      type="flex"
      justify="space-between"
      css={[
        formButtonsCss,
        children
          ? css`
              @media (max-width: 992px) {
                > .ant-col:first-of-type,
                > .ant-col:last-of-type {
                  .ant-btn {
                    display: none;
                  }
                }
              }
            `
          : null,
        css`
          margin-top: 16px;
          > .ant-col {
            align-self: flex-end;
            .ant-btn {
              margin-bottom: 10px;
            }
          }
        `,
      ]}
    >
      {/* <Col>
        <ConfirmationButton
          css={Object.keys(selectedGroups).length ? null : cssUtils.vHidden}
          callback={() => {
            setSelectedOptions([]);
            history.push(`${parentPath}/options`);
          }}
          confirmationText={
            <p>
              Êtes-vous sûr(e) ?<br />
              Les options sélectionnées seront perdues.
            </p>
          }
          confirmationButton="Oui, réinitialiser"
          size="large"
        >
          Réinitialiser
        </ConfirmationButton>
      </Col> */}
      {children ? <Col>{children}</Col> : null}
      <Col span={24} style={{textAlign: 'center'}}>
        <Link
          to={nextGroup?.name ?? ''}
          onClick={(e) => (!nextGroup ? e.preventDefault() : null)}
        >
          <Button
            type="ghost"
            size="large"
            style={{marginRight: '10px'}}
            disabled={
              selectedEars === SELECTED_EARS.NONE ||
              selectedGroup === lastGroup ||
              missingGroups.includes(selectedGroup)
            }
          >
            Étape suivante
          </Button>
        </Link>
        <Tooltip
          title={
            selectedEars === SELECTED_EARS.NONE ? (
              'Sélectionnez au moins une oreille'
            ) : hasMissingCustomMarkField ? (
              'Veuillez saisir des valeurs pour les champs ci-dessus'
            ) : missingGroups.length ? (
              <div>
                <MissingGroupTooltip groups={emptyRequiredGroups} />
                {missingEarsRequiredGroups.map((missingGroups, index) => (
                  <MissingGroupTooltip
                    key={index}
                    groups={missingGroups}
                    suffix={`pour l'oreille ${index ? 'droite' : 'gauche'}`}
                  />
                ))}
              </div>
            ) : null
          }
        >
          <Button
            type="primary"
            size="large"
            disabled={disabled}
            onClick={() => history.push('/cart')}
          >
            Commander
          </Button>
        </Tooltip>
      </Col>
    </Row>
  );
};

export default memo(FormButtons);
