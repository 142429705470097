/* eslint-disable react/react-in-jsx-scope */
import {css} from '@emotion/core';
import {Button, Col, Tooltip, Upload} from 'antd';
import {UploadProps} from 'antd/es/upload';
import {FC, memo, useEffect, useRef, useState} from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import sanitize from 'sanitize-html';
import ConfirmationButton from '../../util/ConfirmationButton';
import {Article} from './index';

const headingLevels = [3, 4];

const quillCss = css`
  .ql-snow {
    &.ql-toolbar {
      text-align: left;
    }

    .ql-picker.ql-header {
      ${headingLevels
        .map(
          (level) => `
    .ql-picker-item[data-value='${level}']::before {
      content: 'Titre ${level}';
    }`,
        )
        .join('\n')}
    }

    .ql-editor {
      max-height: 300px;
    }
  }

  .content {
    * {
      margin: 0;
    }
  }
`;

const quillProps = {
  modules: {
    toolbar: [
      [{header: [...headingLevels, false]}],
      [{color: []}],
      ['bold', 'italic', 'underline', 'blockquote'],
      [{list: 'ordered'}, {list: 'bullet'}],
      ['clean'],
    ],
  },
  formats: [
    'header',
    'bold',
    'italic',
    'underline',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'color',
  ],
};

const sanitizeOptions: sanitize.IOptions = {
  allowedTags: sanitize.defaults.allowedTags.filter(
    (tag) => !['a'].includes(tag),
  ),
};

const MAX_LINES_THRESHOLD = 2;

export interface NewsArticleContentProps {
  article: Article;
  editMode: boolean;
  onArticleChange?: (article: Partial<Article>) => void;
}

const NewsArticleContent: FC<NewsArticleContentProps> = ({
  article,
  editMode,
  onArticleChange,
}) => {
  const contentDiv = useRef<HTMLDivElement | null>(null);
  const [more, setMore] = useState(false);
  const [lessHeight, setLessHeight] = useState(0);

  const {content, image} = article;

  useEffect(() => {
    if (!contentDiv?.current) {
      return;
    }

    const {offsetHeight} = contentDiv.current;

    const lineHeight = parseInt(
      getComputedStyle(contentDiv.current).lineHeight,
    );

    setLessHeight(
      offsetHeight / lineHeight > MAX_LINES_THRESHOLD
        ? MAX_LINES_THRESHOLD * lineHeight
        : 0,
    );
  }, [contentDiv]);

  const showMoreButton = !more && lessHeight;

  const handleUpload: UploadProps['beforeUpload'] = (file): boolean => {
    if (!file) {
      onArticleChange && onArticleChange({image: ''});
      return false;
    }

    const $canvas = document.createElement('canvas');
    const context = $canvas.getContext('2d');

    if (!context) {
      // eslint-disable-next-line no-console
      console.error('Canvas not available');

      return false;
    }

    const $image = document.createElement('img');
    const imageUrl = URL.createObjectURL(file);
    $image.src = imageUrl;
    $image.onload = () => {
      const [width, height] = ([$canvas.height, $canvas.width] = [
        $image.height,
        $image.width,
      ]);

      context.fillStyle = 'white';
      context.fillRect(0, 0, width, height);
      URL.revokeObjectURL(imageUrl);
      context.drawImage($image, 0, 0);

      $canvas.toBlob(
        (blob) =>
          onArticleChange &&
          onArticleChange({
            hasImage: true,
            image: URL.createObjectURL(blob),
          }),
        'image/jpeg',
        0.95,
      );
    };

    return false;
  };

  return (
    <Col css={[{flex: 1}, quillCss]}>
      <div className="image-container">
        {editMode ? (
          <div>
            <Upload
              beforeUpload={handleUpload}
              css={css`
                .ant-upload-list {
                  display: none;
                }
              `}
            >
              <Button css={{margin: '5px'}} icon="upload">
                {image
                  ? "Modifier l'image de l'article"
                  : "Ajouter une image d'article"}
              </Button>
            </Upload>

            {image ? (
              <Tooltip title="Supprimer l'image">
                <ConfirmationButton
                  callback={() =>
                    onArticleChange?.({hasImage: false, image: ''})
                  }
                  confirmationText={
                    <p>
                      Êtes-vous sûr(e) ?<br />
                      L&apos;image sera définitivement perdue.
                    </p>
                  }
                  confirmationButton="Oui, supprrimer l'image"
                  type="danger"
                  icon="delete"
                />
              </Tooltip>
            ) : null}
          </div>
        ) : null}
        {image ? <img src={image} alt="Article" /> : null}
      </div>
      {editMode ? (
        <ReactQuill
          value={content}
          onChange={(content) => onArticleChange && onArticleChange({content})}
          {...quillProps}
        />
      ) : (
        <div
          ref={contentDiv}
          className="content"
          dangerouslySetInnerHTML={{__html: sanitize(content, sanitizeOptions)}}
          css={{
            ...(showMoreButton
              ? {maxHeight: lessHeight, overflow: 'hidden'}
              : {}),
          }}
        />
      )}
      {!editMode && lessHeight ? (
        <Col>
          {!more ? <span css={{float: 'left'}}>...</span> : null}
          <br />
          <div>
            <Button
              type="link"
              onClick={() => setMore(!more)}
              css={{
                float: 'left',

                fontWeight: 'bold',
                padding: 0,
              }}
            >
              <span style={{textDecoration: 'underline'}}>
                Lire {more ? 'moins' : 'plus'}
              </span>
            </Button>
          </div>
        </Col>
      ) : null}
    </Col>
  );
};

export default memo(NewsArticleContent);
