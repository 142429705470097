/* eslint-disable react/react-in-jsx-scope */
import {css} from '@emotion/core';
import {Button, Checkbox, Col, Form, Input, Row, Typography} from 'antd';
import {FormComponentProps} from 'antd/lib/form/Form';
import {FC, FormEventHandler, memo} from 'react';

const {REACT_APP_OFFLINE_MODE} = process.env;
const {Title} = Typography;

const loginFormCss = css`
  margin-left: 150px;

  @media (max-width: 992px) {
    margin-left: 10px;
  }

  .item-container {
    margin: 20px 0;
  }

  .ant-input,
  .ant-input-password {
    background-color: transparent;
    color: white;
    font-weight: bold;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-radius: 0;

    min-width: 400px;

    @media (max-width: 992px) {
      min-width: 150px;
    }

    &:hover {
      background-color: transparent;
    }

    &:focus {
      box-shadow: none;
    }
  }
  .ant-input-affix-wrapper-focused {
    box-shadow: none;
  }

  .anticon {
    color: white;
  }

  .ant-form-item-label > label.ant-form-item-required {
    color: white !important;
    font-weight: bold;
    &::before,
    &::after {
      display: none !important;
    }
    margin-right: 30px;
  }

  .ant-checkbox-wrapper {
    font-weight: bold;
  }

  h1,
  h2,
  .ant-checkbox-wrapper {
    color: white !important;
  }

  h1 {
    font-size: 90px;
    margin-bottom: 0;
    margin-top: 50px;

    @media (max-width: 992px) {
      font-size: 60px;
    }
  }

  h2 {
    font-size: 15px;
    margin: 0 !important;
    letter-spacing: 5px;
    padding-left: 5px;
  }
`;

export interface LoginFormData {
  username: string;
  password: string;
  remember: boolean;
}

export type LoginFormSubmitCallback = (data: LoginFormData) => void;

export interface LoginFormProps extends FormComponentProps<LoginFormData> {
  onSubmit: LoginFormSubmitCallback;
  loading?: boolean;
}

const LoginForm: FC<LoginFormProps> = ({form, onSubmit, loading}) => {
  const {getFieldDecorator, validateFieldsAndScroll} = form;

  const handleSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    validateFieldsAndScroll((err, values) => !err && onSubmit(values));
  };

  return (
    <Form css={loginFormCss} onSubmit={handleSubmit} layout="inline">
      <Title style={{textTransform: 'uppercase'}} level={1}>
        Connexion
      </Title>
      <Title style={{textTransform: 'uppercase'}} level={2}>
        À Interson Protac
      </Title>
      <div className="item-container">
        <Form.Item label="Identifiant">
          {getFieldDecorator('username', {
            initialValue:
              process.env.REACT_APP_WAKANDA_LOGIN ??
              (REACT_APP_OFFLINE_MODE ? 'offline@example.com' : ''),
            rules: [
              {
                required: true,
                message: "Veuillez entrer votre nom d'utilisateur",
              },
            ],
          })(<Input autoFocus={true} />)}
        </Form.Item>
      </div>
      <div className="item-container">
        <Form.Item label="Mot de passe">
          {getFieldDecorator('password', {
            initialValue:
              process.env.REACT_APP_WAKANDA_PASSWORD ??
              (REACT_APP_OFFLINE_MODE ? 'password' : ''),
            rules: [
              {required: true, message: 'Veuillez entrer votre mot de passe'},
            ],
          })(<Input.Password  />)}
        </Form.Item>
      </div>
      <Row type="flex" justify="space-between">
        <Col span={24}>
          <Form.Item css={{position: 'relative', bottom: 3}}>
            {getFieldDecorator('remember', {
              valuePropName: 'checked',
              initialValue: true,
            })(<Checkbox>Se souvenir de moi</Checkbox>)}
          </Form.Item>
        </Col>
        <Col span={24} style={{marginTop: '15px'}}>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            style={{textTransform: 'uppercase', fontWeight: 'bold'}}
          >
            Se connecter
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default memo(LoginForm);
