import {Button, Icon, Input} from 'antd';
import {ColumnProps} from 'antd/lib/table';
import {FilterDropdownProps} from 'antd/lib/table/interface';
import React, {FC} from 'react';
import {getDefaultSearch} from '../components/Orders';
import {BetterOrder} from '../components/Orders/data';
import {StateOf} from './render';

export interface Search {
  searchText: string;
  searchedColumn: string;
}

const SearchFilterDropdown: FC<FilterDropdownProps & {
  dataIndex: string;
  searchState: StateOf<Search>;
}> = ({
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
  dataIndex,
  searchState: [search, setSearch],
}) => {
  const handleSearch = (): void => {
    setSearch({
      searchText: selectedKeys?.[0] as string,
      searchedColumn: dataIndex,
    });
    // eslint-disable-next-line no-unused-expressions
    confirm?.();
  };

  const handleResetFilter = (): void => {
    clearFilters && clearFilters();
    setSearch(getDefaultSearch());
  };

  if (!search.searchText && search.searchedColumn) {
    handleResetFilter();
  }

  return (
    <div style={{padding: 8}}>
      <Input
        placeholder={`Rechercher par "${dataIndex}"`}
        value={selectedKeys?.[0]}
        onChange={(event) =>
          setSelectedKeys?.(event.target.value ? [event.target.value] : [])
        }
        onPressEnter={() => handleSearch()}
        style={{width: 188, marginBottom: 8, display: 'block'}}
      />
      <Button
        type="primary"
        onClick={() => handleSearch()}
        icon="search"
        size="small"
        style={{width: 90, marginRight: 8}}
      >
        Ok
      </Button>
      <Button
        onClick={() => handleResetFilter()}
        size="small"
        style={{width: 90}}
      >
        Réinit.
      </Button>
    </div>
  );
};

export const getFilterDropdownProps: (
  dataIndex: string,
  searchState: StateOf<Search>,
  searchInput?: Input,
) => Partial<ColumnProps<BetterOrder>> = (
  dataIndex,
  searchState,
  searchInput,
): Partial<ColumnProps<BetterOrder>> => ({
  filterDropdown: (props) =>
    SearchFilterDropdown({...props, dataIndex, searchState}),
  onFilterDropdownVisibleChange: (visible: boolean) => {
    if (visible) {
      // FIXME ref missing
      requestAnimationFrame(() => searchInput?.select());
    }
  },
  filterIcon: function FilterIcon(filtered: boolean) {
    return (
      <Icon type="search" style={{color: filtered ? '#1890ff' : undefined}} />
    );
  },
  onFilter: (value, record) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    (record[dataIndex] || '')
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
});
