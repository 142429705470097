import {Descriptions} from 'antd';
import React, {FC, memo, ReactElement} from 'react';
import {
  DEAFNESS_COLORS,
  DEAFNESS_LABEL,
  GENDER_LABEL,
  usePatient,
} from '../../contexts/Quote';

export interface PatientDescriptionProps {
  descriptionSpan?: number;
  title?: string | ReactElement;
  columns?: number;
}
const PatientDescription: FC<PatientDescriptionProps> = ({
  descriptionSpan = 1,
  title = 'Références Patient',
  columns = 3,
}) => {
  const [patient] = usePatient();

  if (!patient?.name) {
    return null;
  }

  return (
    <div style={{margin: '5px 0'}}>
      <Descriptions title={title} layout="horizontal" size="small" column={columns}>
        {patient?.name ? (
          <Descriptions.Item label="Nom" span={descriptionSpan}>
            {patient?.name}
          </Descriptions.Item>
        ) : null}
        {patient?.gender ? (
          <Descriptions.Item label="Sexe" span={descriptionSpan}>
            {GENDER_LABEL[patient?.gender]}
          </Descriptions.Item>
        ) : null}
        {patient?.age ? (
          <Descriptions.Item label="Âge" span={descriptionSpan}>
            {patient?.age}
          </Descriptions.Item>
        ) : null}
        {typeof patient?.deafness === 'number' ? (
          <Descriptions.Item label="Surdité" span={descriptionSpan}>
            <span
              css={{
                fontWeight: 'bold',
                color: DEAFNESS_COLORS[patient.deafness],
              }}
            >
              {DEAFNESS_LABEL[patient.deafness]}
            </span>
          </Descriptions.Item>
        ) : null}
      </Descriptions>
    </div>
  );
};

export default memo(PatientDescription);
