import {Col, Row} from 'antd';
import {FC} from 'react';
import {Link} from 'react-router-dom';

const Banner: FC = () => (
  <Row
    justify="center"
    style={{
      maxWidth: '100%',
      width: '1200px',
      textAlign: 'center',
      margin: 'auto',
      position: 'relative',
    }}
  >
    <Col
      span={24}
      lg={12}
      style={{
        marginBottom: '5px',
        color: '#6D6D6F',
        borderTop: '1px solid #6D6D6F',
        borderBottom: '1px solid #6D6D6F',
        height: '50px',
        lineHeight: '25px',
      }}
    >
      <img
        src="/2022/questions.svg"
        alt="Logo question"
        style={{maxHeight: '50px'}}
      />
      <span style={{textTransform: 'uppercase'}}>Des questions ?&nbsp;</span>
      <Link
        to="/contact"
        style={{fontWeight: 'bold', textDecoration: 'underline'}}
      >
        Contactez-nous
      </Link>
    </Col>
    <Col
      span={24}
      lg={12}
      style={{
        marginBottom: '5px',
        color: '#6D6D6F',
        borderTop: '1px solid #6D6D6F',
        borderBottom: '1px solid #6D6D6F',
        height: '50px',
        lineHeight: '25px',
      }}
    >
      <img
        src="/2022/horloge.svg"
        alt="Logo horaires"
        style={{maxHeight: '50px'}}
      />
      <span style={{textTransform: 'uppercase'}}>Ouvert toute l'année</span>
      {/*&nbsp; <b>9h à 12h30 | 13h30 à 17h30</b> */}
    </Col>
  </Row>
);

export default Banner;
