import {css} from '@emotion/core';
import {Input, Row} from 'antd';
import {FC, memo} from 'react';
import {useComment} from '../../contexts/Quote';

const {TextArea} = Input;

export const MAX_COMMENT_LENGTH = 4000;

const commentCss = css`
  .title {
    line-height: 32px;
    font-weight: bold;
    color: #78787d;
    text-transform: uppercase;
  }

  #comment {
    color: #78787d;
    font-weight: bold;
  }

  p {
    color: #78787d;
  }
`;

const Comment: FC = () => {
  const [comment, setComment] = useComment();

  return (
    <Row css={commentCss}>
      <h3 className="title">
        <label htmlFor="comment">Commentaire</label>
      </h3>
      <TextArea
        id="comment"
        placeholder="Écrivez votre commentaire"
        value={comment || ''}
        onChange={({target}) =>
          setComment(target.value.slice(0, MAX_COMMENT_LENGTH))
        }
        autoSize={{minRows: 3}}
      />
      <p css={{float: 'right'}}>
        {(comment ?? '').length} / {MAX_COMMENT_LENGTH}
      </p>
    </Row>
  );
};

export default memo(Comment);
