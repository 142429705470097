import {Col, Form, Modal, Result, Row} from 'antd';
import {ResultProps} from 'antd/es/result';
import React, {FC, memo, useState} from 'react';
import {useSession} from '../../contexts/Session';
import {cssRowWithoutOuterGutter} from '../../util/render';
import AccountForm, {AccountFormData, AccountFormProps} from './AccountForm';
import AddressForm, {AddressFormProps} from './AddressForm';

const Settings: FC = () => {
  const session = useSession();
  const [modalResult, setModalResult] = useState<Partial<ResultProps> | null>(
    null,
  );

  if (!session) {
    return null;
  }

  const WrappedAccountForm = Form.create<AccountFormProps>({
    name: 'accountForm',
  })(AccountForm);

  const WrappedAddressForm = Form.create<AddressFormProps>({
    name: 'addressForm',
  })(AddressForm);

  const submitAccount = async (data: AccountFormData): Promise<void> => {
    const result = await session.fetch<{success: boolean; reason?: string}>(
      'Clients',
      'postClient',
      data,
    );

    if (!result) {
      return setModalResult({
        status: 'error',
        title: 'Erreur de sauvegarde',
        subTitle:
          'Une erreur inconnue est survenue. Merci de ré-éssayer plus tard.',
      });
    }

    return setModalResult({
      status: result.success ? 'success' : 'error',
      title: result.success
        ? 'Vos données ont été sauvegardées avec succès'
        : 'Erreur de sauvegarde',
      subTitle: result.reason,
    });
  };

  return (
    <Row type="flex" justify="center">
      <Col span={18}>
        <Row gutter={20} css={cssRowWithoutOuterGutter}>
          <h2>Informations utilisateur</h2>
          <Col span={12}>
            <h3>Mon compte</h3>
            <WrappedAccountForm onSubmit={submitAccount} />
          </Col>
          <Col span={12}>
            <h3>Adresse du compte</h3>
            <WrappedAddressForm />
          </Col>
        </Row>
      </Col>

      <Modal
        visible={!!modalResult}
        cancelText="OK"
        onCancel={() => setModalResult(null)}
        okButtonProps={{style: {display: 'none'}}}
      >
        <Result {...modalResult} />
      </Modal>
    </Row>
  );
};

export default memo(Settings);
