import {Col, Form, Input, Modal, Row} from 'antd';
import {ColProps} from 'antd/es/col';
import {GetFieldDecoratorOptions, WrappedFormUtils} from 'antd/es/form/Form';
import {FormComponentProps} from 'antd/lib/form/Form';
import React, {FC, FormEvent, FormEventHandler, memo, useRef} from 'react';
import {Adresse} from '../../Orders/data';
import CountrySelect from '../../util/CountrySelect';

const {TextArea} = Input;

const columnProps: Partial<ColProps> = {
  span: 24,
  md: 12,
};

export interface CustomAddressFormProps extends FormComponentProps<Adresse> {
  onSubmit?: (event: FormEvent<HTMLFormElement>) => void;
  initialValue?: Adresse;
}

const getRequired = (message: string, initialValue?: string): GetFieldDecoratorOptions => ({
  initialValue,
  rules: [{required: true, message}],
});

const CustomAddressForm: FC<CustomAddressFormProps> = ({
  form: {getFieldDecorator},
  onSubmit,
  initialValue,
}) => {
  const handleSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    onSubmit?.(event);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Row type="flex" gutter={20}>
        <Col {...columnProps}>
          <Form.Item label="Raison sociale">
            {getFieldDecorator(
              'raisonSociale',
              getRequired('Veuillez entrer une raison sociale', initialValue?.raisonSociale),
            )(<Input autoFocus={true} />)}
          </Form.Item>
        </Col>
        <Col {...columnProps}>
          <Form.Item label="Adresse">
            {getFieldDecorator(
              'adresse',
              getRequired('Veuillez entrer une adresse', initialValue?.adresse),
            )(<TextArea autoSize={{minRows: 3}} />)}
          </Form.Item>
        </Col>
        <Col {...columnProps} md={6}>
          <Form.Item label="Code postal">
            {getFieldDecorator(
              'codePostal',
              getRequired('Veuillez entrer le code postal', initialValue?.codePostal),
            )(<Input />)}
          </Form.Item>
        </Col>
        <Col {...columnProps} md={18}>
          <Form.Item label="Ville">
            {getFieldDecorator(
              'ville',
              getRequired('Veuillez entrer le nom de la ville', initialValue?.ville),
            )(<Input />)}
          </Form.Item>
        </Col>
        <Col {...columnProps}>
          <Form.Item label="Pays">
            {getFieldDecorator('isoCountry', {initialValue: initialValue?.isoCountry || 'FR'})(
              <CountrySelect />,
            )}
          </Form.Item>
        </Col>
        <Col css={{display: 'none'}}>
          <Form.Item label="ID">
            {getFieldDecorator('ID', {initialValue: '-1'})(<Input />)}
          </Form.Item>
        </Col>
        <Col css={{display: 'none'}}>
          <Form.Item label="Type">
            {getFieldDecorator('type', {initialValue: 'L'})(<Input />)}
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export interface CustomAddressModalProps {
  visible?: boolean;
  onValidate?: (address: Adresse) => void;
  onCancel?: () => void;
  customAddress?: Adresse;
}

const CustomAddressModal: FC<CustomAddressModalProps> = ({
  visible = false,
  onValidate,
  onCancel,
  customAddress,
}) => {
  const WrappedAddressForm = Form.create<CustomAddressFormProps>({
    name: 'addressForm',
  })((props) => <CustomAddressForm {...props} initialValue={customAddress} />);
  const formRef = useRef<{
    getForm: () => null | WrappedFormUtils<Adresse>;
  } | null>(null);

  const handleOk = (): void => {
    formRef?.current
      ?.getForm()
      ?.validateFieldsAndScroll((err, values) => !err && onValidate?.(values));
  };

  return (
    <Modal
      visible={visible}
      onOk={handleOk}
      onCancel={onCancel}
      width={700}
      title="Nouvelle adresse de livraison"
      okText="Ajouter adresse"
      cancelText="Annuler"
    >
      <WrappedAddressForm
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        ref={formRef}
      />
    </Modal>
  );
};

export default memo(CustomAddressModal);
