/* eslint-disable react/react-in-jsx-scope */
import {Button, Col, Row} from 'antd';
import {FC, memo} from 'react';
import {
  TBoolean,
  useCurrentEars,
  useCurrentLockedEars,
} from '../../contexts/Quote';
import ButtonCheckboxGroup, {
  ButtonCheckboxOption,
} from '../../util/ButtonCheckboxGroup';
import {
  EAR,
  getSelectedEarArray,
  getSelectedEars,
  SELECTED_EARS,
  SELECTED_EARS_TEXT,
} from './static';

const earInputOptions: ButtonCheckboxOption<EAR>[] = [
  {
    value: EAR.LEFT,
    title: SELECTED_EARS_TEXT[SELECTED_EARS.LEFT_ONLY],
    label: (
      <img
        css={{height: '120px'}}
        src="/2022/oreille-gauche.svg"
        alt={SELECTED_EARS_TEXT[SELECTED_EARS.LEFT_ONLY]}
      />
    ),
  },
  {
    value: EAR.RIGHT,
    title: SELECTED_EARS_TEXT[SELECTED_EARS.RIGHT_ONLY],
    label: (
      <img
        css={[{height: '120px', transform: 'rotateY(180deg)'}]}
        src="/2022/oreille-gauche.svg"
        alt={SELECTED_EARS_TEXT[SELECTED_EARS.RIGHT_ONLY]}
      />
    ),
  },
];

const EarsInput: FC = () => {
  const [ears, setEars] = useCurrentEars();
  const [lockedEars, setLockedEars] = useCurrentLockedEars();

  const onEarsChange = (earValues: EAR[]): void => {
    const _ears = [EAR.LEFT, EAR.RIGHT].map((ear) =>
      earValues.includes(ear),
    ) as TBoolean;

    setEars(_ears);

    if (setLockedEars && getSelectedEars(_ears) !== SELECTED_EARS.BOTH) {
      setLockedEars(false);
    }
  };

  return (
    <div>
      <Row type="flex" justify="center" css={{margin: '5px 0'}}>
        <Col
          span={24}
          style={{textAlign: 'left', borderBottom: '1px solid #78787d'}}
        >
          <h3
            style={{
              marginBottom: '5px',
              textTransform: 'uppercase',
              color: '#78787d',
              fontWeight: 'bold',
            }}
          >
            Oreilles
            <Button
              type="primary"
              ghost
              size="small"
              onClick={() => setEars([false, false])}
              style={{marginLeft: '10px', textTransform: 'uppercase'}}
            >
              Réinitialiser
            </Button>
          </h3>
        </Col>
        <Col>
          <ButtonCheckboxGroup
            type="radio"
            disabled={lockedEars}
            value={getSelectedEarArray(ears)}
            options={earInputOptions}
            // FIXME https://github.com/DefinitelyTyped/DefinitelyTyped/issues/35834
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            onChange={onEarsChange}
            size="lg"
          />
        </Col>
      </Row>
    </div>
  );
};

export default memo(EarsInput);
