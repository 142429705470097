import {css} from '@emotion/core';
import {Alert, Col, Form, Row} from 'antd';
import {FC, memo, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {useSession} from '../../contexts/Session';
/* eslint-disable react/react-in-jsx-scope */
import LoginForm, {LoginFormProps, LoginFormSubmitCallback} from './Form';

const loginCss = css`
  padding: 0;
  margin: 0 !important;

  .login-container {
    position: absolute;
    left: calc(50% - 300px);

    @media (max-width: 992px) {
      left: 0;
    }
  }

  .form-container {
    text-align: left;
    position: relative;
    left: -300px;
    padding: 0 10px;

    @media (max-width: 992px) {
      left: 0;
    }

    .background-container {
      position: absolute;
      width: 100%;
      margin: auto;

      @media (max-width: 992px) {
        min-width: calc(100vw - 20px);
        overflow: hidden;
      }
    }
  }
`;

export interface LoginProps {
  callback?: string;
}

const tailLayout = {
  wrapperCol: {span: 16},
};

const Login: FC<LoginProps> = ({callback}) => {
  const session = useSession();
  const history = useHistory();
  const {token = localStorage.intersonToken} = useParams();

  if (token) {
    localStorage.intersonToken = token;
  }

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [attemptedTokenLogin, setAttemptedTokenLogin] = useState(false);

  const handleLoginAttempt = async (
    loginAttemptReuslt: boolean,
  ): Promise<void> => {
    setLoading(false);

    if (loginAttemptReuslt) {
      const params = new URLSearchParams(window.location.search);
      if (params.get('action') === 'viewOrder') {
        const ID = params.get('ID');
        if (ID) {
          history.push(`/order/${ID}`);
          return;
        }
      }

      history.push(callback || '/quote');
      return;
    }

    let healthStatus = 500;
    try {
      healthStatus = (
        await fetch(`${process.env.REACT_APP_WAKANDA_HOST}/health`)
      ).status;
    } finally {
      setError(
        healthStatus === 200
          ? "Votre nom d'utilisateur et/ou mot de passe sont invalides. Merci de ré-éssayer"
          : [
              'Connexion au serveur impossible.',
              'Merci vérifier votre connexion, ou de ré-éssayer dans quelques minutes',
            ].join(' '),
      );
    }
  };

  if (token && !attemptedTokenLogin) {
    setAttemptedTokenLogin(true);
    setLoading(true);
    console.log('attempting token login', {
      attemptedTokenLogin,
      token,
    });

    (async () => {
      const [username, password] = [token.substr(0, 40), token.substr(40, 40)];
      handleLoginAttempt(await session.login(username, password));
    })();
  }

  const WrappedLoginForm = Form.create<LoginFormProps>({
    name: 'loginForm',
  })((props) => <LoginForm {...props} loading={loading} />);

  const submit: LoginFormSubmitCallback = async ({
    username,
    password,
    remember,
  }) => {
    setError('');
    setLoading(true);

    handleLoginAttempt(await session.login(username, password, remember));
  };

  return (
    <Row type="flex" justify="center" css={loginCss} gutter={20}>
      <Col span={24}>
        <div className="login-container">
          {error ? (
            <Alert
              message="Erreur de connexion"
              description={error}
              type="error"
              closable
              style={{marginBottom: 20, width: '100%'}}
              onClose={() => setError('')}
            />
          ) : null}
          <div className="form-container">
            <div className="background-container">
              <img
                src="/2022/connexion(1).jpg"
                alt="Fond connexion"
                style={{maxWidth: '1200px'}}
              />
            </div>

            <Row>
              <WrappedLoginForm onSubmit={submit} />
            </Row>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default memo(Login);
