import {UploadFile} from 'antd/lib/upload/interface';
import {Moment} from 'moment';
import {darken, setHue} from 'polished';
import React from 'react';
import {
  createContext,
  useContext,
  useContextSelector,
} from 'use-context-selector';
import {
  DEPORTE_BRAND,
  Product as ProductName,
  PRODUCT_CATEGORY,
  TYPE,
} from '../components/Options/data';
import {Adresse, Transporteur} from '../components/Orders/data';
import {ArticleListItem} from '../components/Summary/util';
import {
  NullableValueDispatcher,
  ValueDispatcher,
  ValuePartialDispatcher,
} from '../util/render';

export enum GENDER {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
}

export enum DEAFNESS {
  LOW,
  MEDIUM,
  HIGH,
  DEEP,
}

export const GENDER_LABEL: {[gender in GENDER]: string} = {
  [GENDER.MALE]: 'Homme',
  [GENDER.FEMALE]: 'Femme',
};

export const DEAFNESS_LABEL: {[deafness in DEAFNESS]: string} = {
  [DEAFNESS.LOW]: 'Faible',
  [DEAFNESS.MEDIUM]: 'Moyenne',
  [DEAFNESS.HIGH]: 'Sévère',
  [DEAFNESS.DEEP]: 'Profonde',
};

const deafnessSteps = Object.values(DEAFNESS_LABEL).length;
export const DEAFNESS_COLORS = Object.fromEntries(
  Object.values(DEAFNESS_LABEL).map((_, index) => [
    index,
    setHue(
      150 * ((deafnessSteps - index - 1) / deafnessSteps),
      darken(0.13, 'red'),
    ),
  ]),
) as {[deafness in DEAFNESS]: string};

export const GENDER_EMOJI: {[gender in GENDER]: string} = {
  [GENDER.MALE]: '♂️',
  [GENDER.FEMALE]: '♀️',
};

export interface PatientData {
  name: string;
  gender?: GENDER;
  age?: number;
  deafness?: DEAFNESS;
  serial?: string;
}

export interface PatientFormData extends PatientData {
  clientZipCode?: string;
  client?: string;
  clientId?: string;
  clientSpecificite?: string;
  boxNumber?: string;
  transporter?: Transporteur;
  comment?: string;
  shippingAddress?: Adresse;
  shippingDate?: Moment;
  waiting?: string;
  warranty?: boolean;
  fabricationMethod?: FabricationMethod;
  serialNumber?: string;
}

export interface BaseProduct {
  label: string;
  subLabel?: string;
  img: string;
  disabled?: true;
  description?: React.ReactNode;
}

export interface Product extends BaseProduct {
  name: ProductName;
}

export interface DeporteBrand extends BaseProduct {
  name: DEPORTE_BRAND;
}

export type Tuple<T> = [T, T];
export type UndefinedTuple<T> = [T | undefined, T | undefined];
export type DumbUndefinedTuple<T> =
  | [T, T]
  | [T, undefined]
  | [undefined, T]
  | [undefined, undefined];
export type TBoolean = Tuple<boolean>;

export interface Option {
  type: TYPE;
  name: string;
  label: string;
  selected: TBoolean;
  value?: UndefinedTuple<number | string>;
  visited?: true;
}

export interface Options {
  ears: TBoolean;
  lockedEars: boolean;
  selected: Option[];
  valid?: boolean;
}

export enum TransferMethod {
  OCTOCLOUD,
  UPLOAD,
  PHSYCIAL,
}

export const transferMethodLabel: {[transfer in TransferMethod]: string} = {
  [TransferMethod.OCTOCLOUD]:
    "J'envoie les fichiers d'empreintes dans OTOCLOUD (OTOMETRICS)",
  [TransferMethod.UPLOAD]:
    "Je joins les fichiers d'empreintes numériques à ma commande",
  [TransferMethod.PHSYCIAL]:
    "J'envoie les empreintes physiques avec mon bon de commande",
};

export const transferMethodOrder: {[transfer in TransferMethod]: number} = {
  [TransferMethod.OCTOCLOUD]: 1,
  [TransferMethod.PHSYCIAL]: 2,
  [TransferMethod.UPLOAD]: 3,
};

export enum FabricationMethod {
  THREE_D = 1,
  TRADITIONAL,
}

export const transferFabricationMethod: {
  [transfer in TransferMethod]: FabricationMethod;
} = {
  [TransferMethod.OCTOCLOUD]: FabricationMethod.TRADITIONAL,
  [TransferMethod.UPLOAD]: FabricationMethod.THREE_D,
  [TransferMethod.PHSYCIAL]: FabricationMethod.TRADITIONAL,
};

export const fabricationMethodRule: {
  [transfer in FabricationMethod]: string;
} = {
  [FabricationMethod.TRADITIONAL]: '/TRD:',
  [FabricationMethod.THREE_D]: '/3D:',
};

export interface ProductCategory extends BaseProduct {
  name: PRODUCT_CATEGORY;
  products: Product[] | {[subCategory: string]: Product[]};
  brands?: DeporteBrand[];
}

export interface QuoteLine {
  id?: string;
  category: ProductCategory;
  subCategory?: string;
  product: Product;
  brand?: DeporteBrand;
  options: Options;
  articles?: ArticleListItem[];
  total?: number;
  valid?: boolean;
}
export type BaseQuoteLine = Partial<QuoteLine> & Pick<QuoteLine, 'options'>;

export interface QuoteData<T extends BaseQuoteLine = QuoteLine> {
  patient: PatientData;
  lines: T[];
  currentLine: T;
  comment?: string;
  terms: boolean;
  customMarkFields?: [string, string];

  client?: string;
  clientSpecificite?: string;
  clientZipCode?: string;
  clientId?: string;
  boxNumber?: string;
  transporter?: Transporteur;
  shippingAddress?: Adresse;
  shippingDate?: string;
  waiting?: string;
  warranty?: boolean;
  fabricationMethod?: FabricationMethod;
  serialNumber?: string;
}

export interface QuoteTransfer {
  method: TransferMethod;
  prints: UploadFile[];
}

export interface BaseQuoteData extends Partial<QuoteData<BaseQuoteLine>> {
  lines: BaseQuoteLine[];
  lineCount: number;
  currentLine: BaseQuoteLine;
  terms: boolean;
  transfer: QuoteTransfer;
}

export const getBaseQuoteLineOptions = (): Options => ({
  ears: [false, false],
  lockedEars: false,
  selected: [],
});
export const getBaseQuoteOptions = (): BaseQuoteData => ({
  currentLine: {options: getBaseQuoteLineOptions()},
  lines: [],
  lineCount: 0,
  terms: false,
  transfer: {method: TransferMethod.UPLOAD, prints: []},
});

const currentLine: BaseQuoteLine = {options: getBaseQuoteLineOptions()};
const QuoteContext = createContext<ValueDispatcher<BaseQuoteData>>([
  {
    currentLine,
    terms: false,
    lines: [],
    lineCount: 0,
    transfer: {method: TransferMethod.UPLOAD, prints: []},
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  () => {},
]);

export const useQuote = (): ValueDispatcher<BaseQuoteData> =>
  useContext(QuoteContext);

export const usePatient = (): NullableValueDispatcher<PatientData> => {
  const patient = useContextSelector(QuoteContext, ([quote]) => quote.patient);
  const setQuote = useContextSelector(
    QuoteContext,
    ([_, setQuote]) => setQuote,
  );
  return [
    patient || null,
    (patient?: PatientData) =>
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      setQuote((quote: BaseQuoteData): BaseQuoteData => ({...quote, patient})),
  ];
};
export const useCurrentLine = (): ValueDispatcher<BaseQuoteLine> => {
  const currentLine = useContextSelector(
    QuoteContext,
    ([quote]) => quote.currentLine,
  );
  const setQuote = useContextSelector(
    QuoteContext,
    ([_, setQuote]) => setQuote,
  );

  return [
    currentLine,
    (currentLine: BaseQuoteLine) =>
      setQuote(
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        (quote: BaseQuoteData): BaseQuoteData => ({
          ...quote,
          currentLine,
        }),
      ),
  ];
};
export const useLines = (): ValueDispatcher<BaseQuoteLine[]> => {
  const lines = useContextSelector(QuoteContext, ([quote]) =>
    quote.lines.filter((line) => !!line),
  );
  const setQuote = useContextSelector(
    QuoteContext,
    ([_, setQuote]) => setQuote,
  );

  return [
    lines,
    (lines: BaseQuoteLine[]) =>
      setQuote(
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        (quote: BaseQuoteData): BaseQuoteData => ({
          ...quote,
          lines,
          lineCount: lines.length,
        }),
      ),
  ];
};

const updateCurrentLine = (
  quote: BaseQuoteData,
  lineData: Partial<BaseQuoteLine>,
): BaseQuoteData => {
  const line = {...quote.currentLine, ...lineData};
  const index = quote.lines.indexOf(quote.currentLine);

  if (index < 0) {
    quote.lines.push(line);
    ++quote.lineCount;
  } else {
    quote.lines.splice(index, 1, line);
  }

  quote.currentLine = line;
  return {...quote};
};

export const useCurrentCategory = (): NullableValueDispatcher<ProductCategory> => {
  const category = useContextSelector(
    QuoteContext,
    ([quote]) => quote.currentLine.category,
  );
  const setQuote = useContextSelector(
    QuoteContext,
    ([_, setQuote]) => setQuote,
  );
  return [
    category || null,
    (category?: ProductCategory) =>
      setQuote(
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        (quote: BaseQuoteData): BaseQuoteData =>
          updateCurrentLine(quote, {category}),
      ),
  ];
};

export const useCurrentSubCategory = (): NullableValueDispatcher<string> => {
  const subCategory = useContextSelector(
    QuoteContext,
    ([quote]) => quote.currentLine.subCategory,
  );
  const setQuote = useContextSelector(
    QuoteContext,
    ([_, setQuote]) => setQuote,
  );
  return [
    subCategory || null,
    (subCategory?: string) =>
      setQuote(
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        (quote: BaseQuoteData): BaseQuoteData =>
          updateCurrentLine(quote, {subCategory}),
      ),
  ];
};
export const useCurrentProduct = (): NullableValueDispatcher<Product> => {
  const product = useContextSelector(
    QuoteContext,
    ([quote]) => quote.currentLine.product,
  );
  const setQuote = useContextSelector(
    QuoteContext,
    ([_, setQuote]) => setQuote,
  );
  return [
    product || null,
    (product?: Product) =>
      setQuote(
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        (quote: BaseQuoteData): BaseQuoteData =>
          updateCurrentLine(quote, {product}),
      ),
  ];
};
export const useCurrentBrand = (): NullableValueDispatcher<DeporteBrand> => {
  const brand = useContextSelector(
    QuoteContext,
    ([quote]) => quote.currentLine.brand,
  );
  const setQuote = useContextSelector(
    QuoteContext,
    ([_, setQuote]) => setQuote,
  );
  return [
    brand || null,
    (brand?: DeporteBrand) =>
      setQuote(
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        (quote: BaseQuoteData): BaseQuoteData =>
          updateCurrentLine(quote, {brand}),
      ),
  ];
};
export const useCurrentOptions = (): ValueDispatcher<Options> => {
  const options = useContextSelector(
    QuoteContext,
    ([quote]) => quote.currentLine.options,
  );
  const setQuote = useContextSelector(
    QuoteContext,
    ([_, setQuote]) => setQuote,
  );

  return [
    options,
    (options: Options) =>
      setQuote(
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        (quote: BaseQuoteData): BaseQuoteData =>
          updateCurrentLine(quote, {options}),
      ),
  ];
};
export const useCurrentEars = (): ValueDispatcher<TBoolean> => {
  const ears = useContextSelector(
    QuoteContext,
    ([quote]) => quote.currentLine.options.ears,
  );
  const setQuote = useContextSelector(
    QuoteContext,
    ([_, setQuote]) => setQuote,
  );

  return [
    ears,
    (ears: TBoolean) =>
      setQuote(
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        (quote: BaseQuoteData): BaseQuoteData =>
          updateCurrentLine(quote, {
            options: {...quote.currentLine.options, ears},
          }),
      ),
  ];
};
export const useCurrentLockedEars = (): ValueDispatcher<boolean> => {
  const lockedEars = useContextSelector(
    QuoteContext,
    ([quote]) => quote.currentLine.options.lockedEars,
  );
  const setQuote = useContextSelector(
    QuoteContext,
    ([_, setQuote]) => setQuote,
  );

  return [
    lockedEars,
    (lockedEars: boolean) =>
      setQuote(
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        (quote: BaseQuoteData): BaseQuoteData =>
          updateCurrentLine(quote, {
            options: {...quote.currentLine.options, lockedEars},
          }),
      ),
  ];
};
export const useCurrentSelectedOptions = (): ValueDispatcher<Option[]> => {
  const selected = useContextSelector(
    QuoteContext,
    ([quote]) => quote.currentLine.options.selected,
  );
  const setQuote = useContextSelector(
    QuoteContext,
    ([_, setQuote]) => setQuote,
  );

  return [
    selected,
    (selected: Option[]) =>
      setQuote(
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        (quote: BaseQuoteData): BaseQuoteData =>
          updateCurrentLine(quote, {
            options: {...quote.currentLine.options, selected},
          }),
      ),
  ];
};
export const useCustomMarkFields = (): NullableValueDispatcher<[
  string,
  string,
]> => {
  const customMarkFields = useContextSelector(
    QuoteContext,
    ([quote]) => quote.customMarkFields,
  );
  const setQuote = useContextSelector(
    QuoteContext,
    ([_, setQuote]) => setQuote,
  );
  return [
    customMarkFields || null,
    (customMarkFields?: [string, string]) =>
      setQuote(
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        (quote: BaseQuoteData): BaseQuoteData => ({...quote, customMarkFields}),
      ),
  ];
};
export const useComment = (): NullableValueDispatcher<string> => {
  const comment = useContextSelector(QuoteContext, ([quote]) => quote.comment);
  const setQuote = useContextSelector(
    QuoteContext,
    ([_, setQuote]) => setQuote,
  );
  return [
    comment || null,
    (comment?: string) =>
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      setQuote((quote: BaseQuoteData): BaseQuoteData => ({...quote, comment})),
  ];
};
export const useTerms = (): ValueDispatcher<boolean> => {
  const terms = useContextSelector(QuoteContext, ([quote]) => quote.terms);
  const setQuote = useContextSelector(
    QuoteContext,
    ([_, setQuote]) => setQuote,
  );
  return [
    terms,
    (terms: boolean) =>
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      setQuote((quote: BaseQuoteData): BaseQuoteData => ({...quote, terms})),
  ];
};
export const useShippingDate = (): ValueDispatcher<string | undefined> => {
  const shippingDate = useContextSelector(
    QuoteContext,
    ([quote]) => quote.shippingDate,
  );
  const setQuote = useContextSelector(
    QuoteContext,
    ([_, setQuote]) => setQuote,
  );
  return [
    shippingDate,
    (shippingDate: string | undefined) =>
      setQuote(
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        (quote: BaseQuoteData): BaseQuoteData => ({...quote, shippingDate}),
      ),
  ];
};

export const useLineCount = (): number =>
  useContextSelector(QuoteContext, ([quote]) => quote.lineCount);

export const useValidLineCount = (): number =>
  useContextSelector(
    QuoteContext,
    ([quote]) => quote.lines.filter(({valid}) => valid).length,
  );

export const useTransfer = (): ValuePartialDispatcher<QuoteTransfer> => {
  const transfer = useContextSelector(
    QuoteContext,
    ([quote]) => quote.transfer,
  );
  const setQuote = useContextSelector(
    QuoteContext,
    ([_, setQuote]) => setQuote,
  );
  return [
    transfer,
    (_transfer: Partial<QuoteTransfer>) =>
      setQuote(
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        (quote: BaseQuoteData): BaseQuoteData => ({
          ...quote,
          transfer: {...transfer, ..._transfer},
        }),
      ),
  ];
};

export default QuoteContext;
