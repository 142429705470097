import {css} from '@emotion/core';
import {Steps} from 'antd';
import {StepProps} from 'antd/lib/steps';
import {FC, memo} from 'react';
import {Redirect, useHistory, useLocation} from 'react-router-dom';
import {useQuote} from '../../contexts/Quote';
import {matchRoutePath} from '../../util/path';
import {DEPORTE_SHAPE, PRODUCT_CATEGORY} from '../Options/data';

const {REACT_APP_SITE_NAME = 'nimes'} = process.env;

const cssProgress = css`
  background-color: #ececf1;
  padding: 10px auto;
  margin-top: 3px;

  @media (max-width: 992px) {
    display: none !important;
  }

  .steps-container {
    flex: 1;
    display: flex;
    max-width: 700px;
    margin: auto;
    max-height: 20px;
  }

  .step-container {
    flex: 1;
  }

  .icon-container {
    margin: auto;
    background-color: #212529;
    width: 40px;
    border-radius: 25px;
    padding: 5px;
    position: relative;
    top: -24px;

    img {
      max-width: 100%;
    }
  }
`;
const cssSteps = css`
  margin: 10px auto;
  max-width: 700px;

  .ant-steps-item {
    flex: 1;
  }

  .ant-steps-item:not(.ant-steps-item-disabled) {
    cursor: pointer;
  }

  .ant-steps-item-title {
    text-transform: uppercase;
    font-weight: bold;
  }

  .ant-steps-item-icon {
    font-weight: bold;
    font-size: 17px;
    line-height: 22px;
  }

  .ant-steps-item-wait {
    opacity: 0.3;
  }

  .ant-steps-item-wait .ant-steps-item-icon {
    background-color: #78787d;
    .ant-steps-icon {
      color: white;
    }
  }

  .ant-steps-item-finish .ant-steps-item-icon {
    border-color: #78787d;
    background-color: #78787d;
    .ant-steps-icon {
      color: white;
    }
  }

  .ant-steps-item-active .ant-steps-item-title {
    color: #dd3214 !important;
  }

  .ant-steps-item-process,
  .ant-steps-item-wait,
  .ant-steps-item-finish {
    > .ant-steps-item-container
      > .ant-steps-item-content
      > .ant-steps-item-title::after {
      display: none;
      background-color: transparent;
      content: '>';
      height: auto;
      color: #d8d8d8;
      position: absolute;
      width: auto;
      position: absolute;
      top: 0;
      font-size: 20px;
      left: 105%;
    }
  }

  .ant-steps-item-process,
  .ant-steps-item-finish {
    > .ant-steps-item-container
      > .ant-steps-item-content
      > .ant-steps-item-title::after {
      color: #f3f3f3;
    }
  }

  @media (max-width: 992px) {
    text-align: left;
    .ant-steps-item-title {
      padding: 0 !important;
    }
  }
`;

const {Step} = Steps;

interface QuoteSteps extends StepProps {
  path: string;
}

const QuoteSteps: FC<{parentPath: string}> = ({parentPath}) => {
  const history = useHistory();
  const location = useLocation();
  const [
    {
      patient,
      currentLine: {category, product, brand},
    },
  ] = useQuote();

  const hasSubCategories = category?.name === PRODUCT_CATEGORY.PROTECTION;
  const hasBrands = product?.name === DEPORTE_SHAPE.DEPORTE;

  const steps: QuoteSteps[] = [
    {path: 'patient', title: 'Références'},
    {path: 'categories', title: 'Catégories', disabled: !patient},
    ...(hasSubCategories
      ? [
          {
            path: 'domain',
            title: 'Domaines',
            disabled: !category,
          },
        ]
      : []),
    {path: 'products', title: 'Produits', disabled: !category},
    ...(hasBrands
      ? [{path: 'brands', title: 'Marques', disabled: !product}]
      : []),
    {
      path: 'options',
      title: 'Options',
      disabled: hasBrands ? !brand || !product : !product,
    },
  ];

  const currentStep = matchRoutePath(
    location.pathname,
    steps,
    `${parentPath}/`,
  );
  const currentStepIndex = currentStep ? steps.indexOf(currentStep) : -1;

  if (currentStep && currentStep.disabled) {
    return <Redirect to={[parentPath, steps[0].path].join('/')} />;
  }

  return (
    <div>
      <Steps
        size="small"
        current={currentStepIndex >= 0 ? currentStepIndex : undefined}
        css={cssProgress}
      >
        <div className="steps-container">
          {steps.map((_, index) => (
            <div
              className="step-container"
              style={{
                visibility: index === currentStepIndex ? 'visible' : 'hidden',
              }}
            >
              <div className="icon-container" key={index}>
                <img src={`/2022/${REACT_APP_SITE_NAME}.svg`} alt="Logo site" />
              </div>
            </div>
          ))}
        </div>
      </Steps>
      <Steps
        size="small"
        current={currentStepIndex >= 0 ? currentStepIndex : undefined}
        css={cssSteps}
      >
        {steps.map(({path, ...props}) => (
          <Step
            key={path}
            {...props}
            onClick={
              props.disabled
                ? undefined
                : () => history.push([parentPath, path].join('/'))
            }
          />
        ))}
      </Steps>
    </div>
  );
};

export default memo(QuoteSteps);
