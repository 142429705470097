import React, {forwardRef, memo, ReactElement, ReactNode} from 'react';
import ButtonCheckboxGroup from './ButtonCheckboxGroup';

export interface ButtonRadioOption<T> {
  readonly value: T;
  readonly className?: string;
  readonly label: string | ReactNode;
}

export interface ButtonRadioGroupProps<T> {
  readonly options: ButtonRadioOption<T>[];
  readonly value?: T;
  readonly onChange?: (value: T | null) => void;
  readonly size?: 'sm' | 'md' | 'lg';
}

// eslint-disable-next-line react/display-name
const ButtonRadioGroup = function ButtonRadioGroup<T, K = {}>(
  {onChange, value, options, size}: ButtonRadioGroupProps<T>,
  ref: K,
): ReactElement {
  return (
    <ButtonCheckboxGroup
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      ref={ref}
      options={options}
      value={value ? [value] : []}
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      onChange={(values: T[] | null) => {
        onChange && onChange(values ? values[0] : null);
      }}
      maxSelectedValues={1}
      size={size}
    />
  );
};

export default memo(forwardRef(ButtonRadioGroup));
