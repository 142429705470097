import AwesomeDebouncePromise from 'awesome-debounce-promise';
import {useState} from 'react';
import {AsyncState, useAsync} from 'react-async-hook';
import useConstant from 'use-constant';
import {BaseQuoteData} from '../../contexts/Quote';
import {useSession} from '../../contexts/Session';
import {WakandaService} from '../../util/fetch-wakanda';
import {StateOf} from '../../util/render';
import {TypeaheadClient} from '../Orders/data';
import {Article, TargetType} from './index';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface TypeaheadLine<T = any> {
  id: string;
  label: string;
  row: T;
}

export interface TypeaheadResult<T> {
  lines: T[];
  total: number;
}

interface TypeaheadFilter {
  filter?: string;
  ids?: string[];
  offset?: number;
  zipCode?: string;
}

interface TypeaheadGroup {
  ID: string;
  libelle: string;
}

export const RESULT_LIMIT = 50;

const getTypeaheadEntities = async <T>(
  session: WakandaService,
  filter: TypeaheadFilter,
  [target, func]: [string, string],
  entityToLine: (line: T) => TypeaheadLine,
): Promise<TypeaheadResult<TypeaheadLine>> => {
  const res = await session.fetch<TypeaheadResult<T>>(target, func, filter);

  if (!res) {
    return {lines: [], total: 0};
  }

  return {
    lines: res.lines.map(entityToLine),
    total: Math.max((res.total || 0) - res.lines.length, 0),
  };
};

const getTypeaheadLines = (
  session: WakandaService,
  targetType: TargetType,
  filter: TypeaheadFilter,
): Promise<TypeaheadResult<TypeaheadLine>> => {
  switch (targetType) {
    case TargetType.CLIENT:
      return getTypeaheadEntities<TypeaheadClient>(
        session,
        filter,
        ['Clients', 'getClients'],
        (client) => ({id: client.ID, label: client.raisonSociale, row: client}),
      );
    case TargetType.GROUP:
      return getTypeaheadEntities<TypeaheadGroup>(
        session,
        filter,
        ['CLT_groupe', 'getGroupes'],
        (group) => ({id: group.ID, label: group.libelle, row: group}),
      );
    default:
      throw new Error(`unknown target type: ${targetType}`);
  }
};

export const useNewsArticleTypeahead = (
  article?: Article,
): {
  searchInputState: StateOf<string>;
  targetTypeState: StateOf<TargetType | undefined>;
  searchOffsetState: StateOf<number>;
  search: AsyncState<TypeaheadResult<TypeaheadLine>>;
  fetching: boolean;
} => {
  const session = useSession();
  const [targetType, setTargetType] = useState<TargetType | undefined>(
    article?.targetType ?? undefined,
  );
  const [searchInput, setSearchInput] = useState('');
  const [fetching, setFetching] = useState(false);
  const [hasInitialFetched, setHasInitialFetched] = useState(false);
  const [searchOffset, setSearchOffset] = useState(0);

  const debouncedSearch = useConstant(() =>
    AwesomeDebouncePromise(getTypeaheadLines, 300),
  );

  const search = useAsync(
    async (
      session: WakandaService,
      searchInput: string,
      searchOffset: number,
      targetType?: TargetType,
    ): Promise<TypeaheadResult<TypeaheadLine> | undefined> => {
      if (!searchInput && !hasInitialFetched) {
        setHasInitialFetched(true);
        if (targetType === undefined || !article?.targets) {
          return;
        }

        return getTypeaheadLines(session, targetType, {ids: article.targets});
      }

      if (!searchInput || targetType === undefined) {
        return;
      }

      setFetching(true);
      const result = await debouncedSearch(session, targetType, {
        filter: searchInput,
        offset: searchOffset,
      });
      setFetching(false);

      return searchOffset > 0
        ? {
            lines: [...(search.result?.lines ?? []), ...result.lines],
            total: result.total,
          }
        : result;
    },
    [session, searchInput, searchOffset, targetType],
    {setLoading: (state) => state},
  );

  return {
    searchInputState: [searchInput, setSearchInput],
    targetTypeState: [targetType, setTargetType],
    searchOffsetState: [searchOffset, setSearchOffset],
    search,
    fetching,
  };
};

export const useClientTypeahead = (
  quote?: BaseQuoteData,
  clientZipCode?: string,
  clientHasFocus = false,
): {
  searchInputState: StateOf<string>;
  searchOffsetState: StateOf<number>;
  search: AsyncState<TypeaheadResult<TypeaheadLine>>;
  fetching: boolean;
} => {
  const session = useSession();
  const [searchInput, setSearchInput] = useState('');
  const [fetching, setFetching] = useState(false);
  const [hasInitialFetched, setHasInitialFetched] = useState(false);
  const [searchOffset, setSearchOffset] = useState(0);

  const debouncedSearch = useConstant(() =>
    AwesomeDebouncePromise(getTypeaheadLines, 300),
  );

  const search = useAsync(
    async (
      session: WakandaService,
      searchInput: string,
      searchOffset: number,
      clientZipCode?: string,
      clientHasFocus?: boolean,
    ): Promise<TypeaheadResult<TypeaheadLine> | undefined> => {
      if (!searchInput && !hasInitialFetched) {
        setHasInitialFetched(true);

        if (quote?.clientId) {
          return getTypeaheadLines(session, TargetType.CLIENT, {
            ids: [quote.clientId],
          });
        }

        return;
      }

      if (!searchInput && !clientHasFocus) {
        return;
      }

      setFetching(true);
      const result = await debouncedSearch(session, TargetType.CLIENT, {
        filter: searchInput,
        offset: searchOffset,
        zipCode: clientZipCode,
      });
      setFetching(false);

      return searchOffset > 0
        ? {
            lines: [...(search.result?.lines ?? []), ...result.lines],
            total: result.total,
          }
        : result;
    },
    [session, searchInput, searchOffset, clientZipCode, clientHasFocus],
    {setLoading: (state) => state},
  );

  return {
    searchInputState: [searchInput, setSearchInput],
    searchOffsetState: [searchOffset, setSearchOffset],
    search,
    fetching,
  };
};
