/* eslint-disable react/react-in-jsx-scope */
import {css} from '@emotion/core';
import {Button, Col, Row} from 'antd';
import {FC, Fragment} from 'react';
import {useHistory} from 'react-router-dom';
import {
  Option,
  TBoolean,
  useCurrentLockedEars,
  useCurrentSelectedOptions,
} from '../../contexts/Quote';
import {OptionsGroup} from './OptionsGroup';
import {OptionsGroupPicker} from './OptionsGroupPicker';
import {
  getSelectedEars,
  Group,
  OPTION_GROUP_CHECK_TYPE,
  SelectableOption,
  SELECTED_EARS,
} from './static';

interface Props {
  ears: TBoolean;
  groups: Group[];
  options: SelectableOption[];
  selectedGroup: Group | undefined;
  setSelectedOption: (
    option: Option,
    checkType: OPTION_GROUP_CHECK_TYPE,
  ) => void;
  parentPath: string;
}

export const OptionsGroupsRows: FC<Props> = ({
  ears,
  groups,
  options,
  selectedGroup,
  setSelectedOption,
  parentPath,
}) => {
  const [lockedEars] = useCurrentLockedEars();
  const history = useHistory();
  const [_selectedOptions, setSelectedOptions] = useCurrentSelectedOptions();

  if (!groups.length) {
    return null;
  }

  return (
    <Fragment>
      <div style={{textAlign: 'left', borderBottom: '1px solid #78787d'}}>
        <h3
          style={{
            marginBottom: '5px',
            textTransform: 'uppercase',
            color: '#78787d',
            fontWeight: 'bold',
          }}
        >
          Options d&apos;oreilles
          <Button
            type="primary"
            ghost
            size="small"
            onClick={() => {
              setSelectedOptions([]);
              history.push(`${parentPath}/options`);
            }}
            style={{marginLeft: '10px', textTransform: 'uppercase'}}
          >
            Réinitialiser
          </Button>
        </h3>
      </div>
      <br />

      <div>
        <div
          css={[
            {
              display: 'flex',
              textAlign: 'center',
              color: 'white',
            },
            css`
              padding: 0;
              @media (min-width: 992px) {
                font-size: 17px;
              }
            `,
          ]}
        >
          <Col span={8} />
          <Col span={16}>
            <Row type="flex" justify="center">
              <Col span={18} style={{maxWidth: 'calc(75% - 6px)'}}>
                <br />
              </Col>
              {getSelectedEars(ears) !== SELECTED_EARS.BOTH || !lockedEars ? (
                ears.map((enabled, earIndex) => (
                  <Col
                    span={3}
                    key={earIndex}
                    style={{borderLeft: '2px solid #78787d'}}
                  >
                    <img
                      css={[
                        {
                          height: '35px',
                          transform: earIndex ? 'rotateY(180deg)' : '',
                        },
                      ]}
                      src="/2022/oreille-gauche.svg"
                      alt={`Oreille ${earIndex ? 'droite' : 'gauche'}`}
                    />
                  </Col>
                ))
              ) : (
                <Col css={{flex: 2}}>Les deux</Col>
              )}
            </Row>
          </Col>
        </div>
      </div>
      <Row>
        <Col span={8}>
          <OptionsGroupPicker groups={groups} ears={ears} />
        </Col>
        <Col span={16}>
          <OptionsGroup
            options={options}
            checkType={
              selectedGroup?.checkType ||
              (selectedGroup?.unique
                ? OPTION_GROUP_CHECK_TYPE.RADIO
                : OPTION_GROUP_CHECK_TYPE.CHECKBOX)
            }
            setSelectedOption={setSelectedOption}
          />
        </Col>
      </Row>
    </Fragment>
  );
};
