/* eslint-disable react/react-in-jsx-scope */
import {css} from '@emotion/core';
import {Col, Row} from 'antd';
import {FC, memo, useState} from 'react';
import {Redirect, useLocation} from 'react-router-dom';
import {useCurrentSubCategory, useQuote} from '../contexts/Quote';
import {useSession, useUser} from '../contexts/Session';
import {UserType} from '../util/fetch-wakanda';
import {cssUtils} from '../util/render';
import News from './News';
import {PRODUCT_CATEGORY} from './Options/data';
import Pitch from './Pitch';
import Quote from './Quote';

const pitchCss = css`
  position: relative;
  top: -15px;
  left: 0;
  width: 100%;
  z-index: 1;
`;

const logoCss = css`
  display: none;

  max-height: 50px;
  position: absolute;
  right: 10px;
  top: -150px;
  z-index: 1;
  top: 82px;
`;

const Landing: FC = () => {
  const user = useUser();
  const session = useSession();
  const [imageErrorStatus, setImageErrorStatus] = useState(false);
  const {hash, pathname} = useLocation();

  const [quote] = useQuote();
  const [subCategory] = useCurrentSubCategory();

  let foreground = '';
  if (quote?.currentLine?.category) {
    const {category} = quote?.currentLine;
    switch (category.name) {
      case PRODUCT_CATEGORY.CLASSIQUE: {
        foreground = 'Classique';
        break;
      }
      case PRODUCT_CATEGORY.PROTECTION: {
        if (pathname.endsWith('/products') || pathname.includes('/options')) {
          foreground = subCategory || 'Protection';
        } else {
          foreground = 'Protection';
        }
        break;
      }
    }
  }

  if (!user || !session) {
    const loginTokenMatch = hash.match(/#!\/login\/(.{80})/);
    return (
      <Redirect
        to={['/login', loginTokenMatch ? '/' + loginTokenMatch[1] : ''].join(
          '',
        )}
        push={true}
      />
    );
  }

  return (
    <Row type="flex" justify="center" css={cssUtils.heightP()}>
      <Col span={24} style={{paddingBottom: '50px', maxWidth: '1200px'}}>
        <Col css={{flex: 1, textAlign: 'center'}}>
          <div css={pitchCss}>
            <Pitch
              background="Embouts"
              foreground={foreground || 'Je commande mes'}
            />
          </div>
          {user.type === UserType.CLIENT && !imageErrorStatus ? (
            <img
              src={`${process.env.REACT_APP_ASSETS_HOST}/centrale/${user.groupID}.png`}
              alt="Logo centrale"
              css={logoCss}
              onError={() => setImageErrorStatus(true)}
            />
          ) : null}
          <Quote parentPath="/quote" />
          <div style={{marginBottom: '50px'}}>
            <News single={true} />
          </div>
        </Col>
      </Col>
    </Row>
  );
};

export default memo(Landing);
