import {css} from '@emotion/core';
import {Button, Col, Layout, Row} from 'antd';
import {FC, memo} from 'react';
import {Link, useLocation} from 'react-router-dom';
/* eslint-disable react/react-in-jsx-scope */

const {Footer: AntFooter} = Layout;

const baseUrl = 'https://espace-pro.interson-protac.com';

const footerCss = css`
  background-color: #212529;
  color: #7d7b81;
  padding: 15px 60px;
  width: 100%;
  min-height: 120px;
  bottom: 0;

  .visible-sm {
    display: none;
  }

  @media (max-width: 992px) {
    padding: 5px 10px !important;
    height: auto !important;
    .visible-xl {
      display: none;
    }
    .visible-sm {
      display: initial !important;
    }
  }
`;

const Footer: FC = () => {
  const location = useLocation();

  if (location.pathname === '/login') return null;

  return (
    <AntFooter css={footerCss}>
      <Row
        gutter={15}
        style={{
          textAlign: 'left',
          lineHeight: '10px',
          display: 'flex',
          justifyContent: 'space-around',
        }}
      >
        <Col xl={3} style={{maxWidth: '256px'}}>
          <Link to="/">
            <img
              src="/IntersonProtacbyProdways_transparent.png"
              alt="Logo Interson by Prodways"
              style={{
                maxWidth: '100%',
                position: 'relative',
                top: '18px',
              }}
            />
          </Link>
        </Col>
        <Col
          span={21}
          style={{textAlign: 'left', lineHeight: '17px', margin: '5px 0'}}
        >
          <b>
            CHEZ INTERSON BY PROD<span style={{color: '#dd3214'}}>WAYS</span>
            ...
          </b>
          <br />
          Le bruit est et a toujours été notre ennemi n°1. Présent dans chacun
          des moments de la vie, à la maison comme au travail, il vient nuire et
          gâcher ces instants si précieux. <br />
          <u>Chez Interson Protac, nous ne pouvons laisser cela se produire.</u>
          &nbsp; C&apos;est pourquoi, chacun de nos collaborateurs œuvrent,
          chaque jour, à vous proposer le meilleur de l&apos;embout de prothèse
          et des protections auditives sur mesure afin que vous puissiez
          profiter de vos activités sans avoir a vous soucier du bruit et de son
          impact sur votre santé.
        </Col>

        {/* <Col
          span={7}
          xl={4}
          style={{
            textAlign: 'center',
            borderLeft: '1px solid #7D7B81',
            margin: '5px 0',
            padding: '0 10px',
          }}
        >
          <div
            style={{fontSize: '12px', fontWeight: 'bold', lineHeight: '15px'}}
          >
            Vous avez besoin d&apos;informations, de
            <br />
            poser une question, une demande...?
          </div>
          <br />
          <Button
            className="visible-sm"
            type="primary"
            style={{textTransform: 'uppercase'}}
          >
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`${baseUrl}/contact`}
            >
              Contact
            </a>
          </Button>
          <Button
            className="visible-xl"
            size="large"
            type="primary"
            style={{textTransform: 'uppercase'}}
          >
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`${baseUrl}/contact`}
            >
              Contactez-nous
            </a>
          </Button>
        </Col> */}
      </Row>
    </AntFooter>
  );
};

export default memo(Footer);
