import {Form, Input, Select} from 'antd';
import {FormComponentProps} from 'antd/lib/form/Form';
import React, {FC, FormEventHandler, memo} from 'react';
import {Redirect} from 'react-router-dom';
import {useUser} from '../../contexts/Session';

const {Option} = Select;
const {TextArea} = Input;

export interface AddressFormData {
  name: string;
  address: string;
  zip: string;
  city: string;
  country: string;
}

export type AddressFormSubmitCallback = (data: AddressFormData) => void;

export interface AddressFormProps extends FormComponentProps<AddressFormData> {
  onSubmit?: AddressFormSubmitCallback;
}

const AddressForm: FC<AddressFormProps> = ({form, onSubmit}) => {
  const user = useUser();
  if (!user) {
    return <Redirect to="/login" push={true} />;
  }

  const {getFieldDecorator, validateFieldsAndScroll} = form;

  const handleSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    validateFieldsAndScroll((err, values) => !err && onSubmit?.(values));
  };

  const disabled = {disabled: true};

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Item label="Raison sociale">
        {getFieldDecorator('name', {initialValue: user.adr_fact?.rs})(
          <Input {...disabled} />,
        )}
      </Form.Item>
      <Form.Item label="Adresse">
        {getFieldDecorator('address', {initialValue: user.adr_fact?.adr})(
          <TextArea {...disabled} />,
        )}
      </Form.Item>
      <Form.Item label="Code postal">
        {getFieldDecorator('zipCode', {initialValue: user.adr_fact?.cp})(
          <Input {...disabled} />,
        )}
      </Form.Item>
      <Form.Item label="Ville">
        {getFieldDecorator('city', {initialValue: user.adr_fact?.ville})(
          <Input {...disabled} />,
        )}
      </Form.Item>
      <Form.Item label="Pays">
        {getFieldDecorator('country', {
          initialValue: user.adr_fact?.pays || 'FR',
        })(
          <Select {...disabled}>
            <Option value="BE">Belgique</Option>
            <Option value="LU">Luxembourg</Option>
            <Option value="CH">Suisse</Option>
            <Option value="FR">France</Option>
          </Select>,
        )}
      </Form.Item>
      {/*<Button type="primary" htmlType="submit" icon="save">
        Valider
      </Button>*/}
    </Form>
  );
};

export default memo(AddressForm);
