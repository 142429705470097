/* eslint-disable react/react-in-jsx-scope */
import {css} from '@emotion/core';
import {Button, Col, Row} from 'antd';
import {FC, memo, useEffect, useState} from 'react';
import {Redirect, useLocation} from 'react-router-dom';
import {UndefinedTuple} from '../../contexts/Quote';
import {useSession, useUser} from '../../contexts/Session';
import {UserType, WakandaService} from '../../util/fetch-wakanda';
import NewsArticle from './Article';

const newsCss = css`
  .collapse-button {
    display: inline-block;
    img {
      max-width: 20px;
    }
  }
`;

const {REACT_APP_OFFLINE_MODE} = process.env;

export enum TargetType {
  CLIENT = 0,
  GROUP = 1,
  ALL = 2,
}

export const TargetTypeLabel: {[target in TargetType]: string} = {
  [TargetType.CLIENT]: 'Clients',
  [TargetType.GROUP]: 'Groupes',
  [TargetType.ALL]: 'Tous les utilisateurs',
};

export interface Article {
  id: number;
  hasImage?: boolean;
  image?: string;
  title: string;
  content: string;
  editMode?: boolean;
  publicationDate: string;
  visibilityDates?: UndefinedTuple<string>;
  targetType?: TargetType;
  targets?: string[];
}

export interface WakArticle {
  ID: number;
  title: string;
  body: string;
  creationDate: string;
  visibilityStartDate: string;
  visibilityEndDate: string;
  targetType: string;
  targets: string[];
  image: string;
  hasImage?: boolean;
}

const testArticle: WakArticle = {
  ID: 123,
  title: 'Codes LPP définitifs',
  body:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  creationDate: '2022-01-01T10:12:12Z',
  visibilityStartDate: '2020-01-01T10:12:12Z',
  visibilityEndDate: '2024-01-01T10:12:12Z',
  targetType: '2',
  targets: [],
  hasImage: false,
  image: '',
};

const fetchArticles = async (session: WakandaService): Promise<WakArticle[]> =>
  (await session.fetch<WakArticle[]>('News', 'get')) || [];

const convertFromWak = (articles: WakArticle[]): Article[] =>
  (articles.map &&
    articles.map<Article>(
      ({
        ID,
        title,
        body,
        creationDate,
        visibilityStartDate,
        visibilityEndDate,
        targetType,
        targets,
        hasImage,
      }) => ({
        id: ID,
        title,
        content: body,
        publicationDate: creationDate,
        visibilityDates: [visibilityStartDate, visibilityEndDate],
        targetType: Number(targetType) as TargetType,
        targets,
        image: hasImage
          ? [process.env.REACT_APP_ASSETS_HOST, 'news', `${ID}.jpg`].join('/')
          : '',
      }),
    )) ||
  [];

const News: FC<{single?: boolean}> = ({single = false}) => {
  const {pathname} = useLocation();
  const session = useSession();
  const user = useUser();
  const [articles, setArticles] = useState<Article[]>([]);
  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    let abort = false;
    (async () => {
      if (user && !user.isGuest) {
        if (REACT_APP_OFFLINE_MODE) {
          setArticles(convertFromWak([testArticle, testArticle, testArticle]));
          return;
        }

        const _articles = await fetchArticles(session);
        if (abort) {
          return;
        }
        setArticles(convertFromWak(_articles));
      }
    })();

    return () => {
      abort = true;
    };
  }, [session, user]);

  if (!user) {
    return <Redirect to="/login" push={true} />;
  }

  const handleArticleSave = (previousArticle: Article) => async (
    newArticle: Article,
  ): Promise<void> => {
    const formData = new FormData();
    if (newArticle.id >= 0) {
      formData.append('ID', newArticle.id.toString());
    }

    formData.append('title', newArticle.title);
    formData.append('body', newArticle.content);
    if (newArticle.visibilityDates?.[0]) {
      formData.append('visibilityStartDate', newArticle.visibilityDates[0]);
    }
    if (newArticle.visibilityDates?.[1]) {
      formData.append('visibilityEndDate', newArticle.visibilityDates[1]);
    }
    if (newArticle.targetType != null) {
      formData.append('targetType', newArticle.targetType.toString());
    }
    formData.append('targets', JSON.stringify(newArticle.targets || []));
    formData.append('hasImage', JSON.stringify(newArticle.hasImage || false));
    if (newArticle.image?.startsWith('blob:')) {
      formData.append('image', await (await fetch(newArticle.image)).blob());
    }

    (async () => {
      const wakArticle = (await (
        await fetch(`${process.env.REACT_APP_WAKANDA_HOST}/postNews`, {
          method: 'POST',
          credentials: 'include',
          body: formData,
        })
      ).json()) as WakArticle;

      newArticle.id = wakArticle.ID;
      newArticle.editMode = false;

      const _articles = [...articles];
      _articles.splice(articles.indexOf(previousArticle), 1, newArticle);
      setArticles(_articles);
    })();
  };

  const handleArticleDelete = async (article: Article): Promise<void> => {
    if (article.id >= 0) {
      await session.fetch<WakArticle[]>('News', 'delete', {ID: article.id});
    }

    setArticles(articles.filter((_article) => _article !== article));
  };

  if (!pathname.endsWith('/patient')) return null;

  if (single) {
    return (
      <Row>
        <Col span={24} lg={{span: 18, offset: 6}}>
          <Row type="flex" css={[newsCss, {width: '100%', marginTop: '30px'}]}>
            <Col span={22} offset={1} lg={{span: 12, offset: 6}}>
              <Row
                type="flex"
                style={{
                  backgroundColor: '#ECECF1',
                  ...(collapsed ? {display: 'none'} : {}),
                }}
              >
                {articles.slice(0, 1).map((article, index) => (
                  <Col span={24} key={index}>
                    <NewsArticle article={article} />
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }

  return (
    <Row type="flex" justify="center" css={newsCss}>
      <Col css={{width: '100%'}}>
        <Row
          type="flex"
          justify="space-between"
          css={css`
            .ant-col {
              margin: 10px;
            }
          `}
        >
          <Col style={{margin: 0}}>
            <h2
              css={{
                margin: 0,
                lineHeight: '32px',
                fontSize: '14px',
                color: '#78777f',
              }}
            >
              Fil d&apos;actualités
              <Button
                type="link"
                className="collapse-button"
                onClick={() => setCollapsed(!collapsed)}
              >
                <img
                  src="/arrow_up.png"
                  alt="collapse"
                  style={collapsed ? {transform: 'rotate(180deg)'} : {}}
                />
              </Button>
            </h2>
          </Col>
          <Col>
            {user.type === UserType.ADMIN ? (
              <Button
                type="primary"
                icon="plus"
                css={{marginLeft: 10}}
                onClick={() =>
                  setArticles([
                    {
                      id: -articles.length,
                      title: '',
                      content: '',
                      editMode: true,
                      publicationDate: new Date().toISOString(),
                    },
                    ...articles,
                  ])
                }
              >
                Nouvel article
              </Button>
            ) : null}
          </Col>
        </Row>

        <Row
          type="flex"
          style={{
            backgroundColor: '#ECECF1',
            ...(collapsed ? {display: 'none'} : {}),
          }}
        >
          {articles.map((article, index) => (
            <Col span={24} lg={8} key={index}>
              <NewsArticle
                article={article}
                onArticleSave={handleArticleSave(article)}
                onArticleDelete={handleArticleDelete}
                editRights={user.type === UserType.ADMIN}
              />
            </Col>
          ))}
        </Row>
      </Col>
    </Row>
  );
};

export default memo(News);
