import {css} from '@emotion/core';
import {Button, Card, Col, Divider, Form, Row, Tooltip} from 'antd';
import React, {FC, memo, useState} from 'react';
import PatientForm from '../Quote/patient/Form';
import OrderDescription from './OrderDescription';
import PatientDescription from './PatientDescription';

const patientCss = css`
  border: none;

  .ant-card-head {
    padding-left: 0;
    .ant-card-head-title {
      padding-bottom: 5px;
    }

    .title {
      line-height: 32px;
      font-weight: bold;
      color: #78787d;
      text-transform: uppercase;
    }
  }

  .ant-card-body {
    border-top: solid 1px #78787d;
    background-color: #ececf1;
    color: #78787d;

    .ant-descriptions-item {
      .ant-descriptions-item-label {
        color: #78787d;
      }

      .ant-descriptions-item-content {
        font-weight: bold;
        color: #78787d;
      }
    }
  }
`;

export interface PatientProps {
  patientOnly?: boolean;
}

const Patient: FC<PatientProps> = ({patientOnly = false}) => {
  const [editMode, setEditMode] = useState(false);
  const WrappedPatientForm = Form.create({name: 'patientForm'})((props) => (
    <PatientForm
      {...props}
      afterSubmit={() => setEditMode(false)}
      patientOnly={patientOnly}
    />
  ));

  return (
    <Card
      css={patientCss}
      title={
        <Row type="flex" justify="space-between">
          <Col className="title">Informations Patient</Col>
          <Col>
            <Tooltip
              title={
                editMode
                  ? 'Annuler les modifications'
                  : 'Modifier infos patient'
              }
            >
              <Button
                type="default"
                onClick={() => setEditMode(!editMode)}
                css={{marginRight: '2px'}}
                shape={editMode ? 'round' : 'circle'}
                style={editMode ? {} : {border: 'none'}}
              >
                {editMode ? (
                  'Annuler'
                ) : (
                  <img src="2022/bouton-modification.svg" alt="Modifier" />
                )}
              </Button>
            </Tooltip>
          </Col>
        </Row>
      }
    >
      {editMode ? (
        <WrappedPatientForm />
      ) : (
        <div>
          {!patientOnly ? (
            <div>
              <OrderDescription title="" />
              <Divider />
            </div>
          ) : null}
          <div>
            <PatientDescription title="" columns={4}/>
          </div>
        </div>
      )}
    </Card>
  );
};

export default memo(Patient);
