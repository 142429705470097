const getAccumulatedPathComponents = (path: string): string[] => {
  const accComponents: string[] = [];
  for (const component of path.replace(/^\//, '').split('/')) {
    accComponents.push(
      accComponents.length
        ? [accComponents[accComponents.length - 1], component].join('/')
        : '/' + component,
    );
  }
  return accComponents;
};

export const matchRoutePath = <T extends {path: string}>(
  location: string,
  routes: T[],
  prefix = '',
): T | null => {
  for (const partialPath of getAccumulatedPathComponents(location).reverse()) {
    const path = routes.find(
      ({path}) => partialPath === [prefix, path].join(''),
    );
    if (path) {
      return path;
    }
  }

  return null;
};
