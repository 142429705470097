import React, {FC, memo} from 'react';
import {Adresse} from '../Orders/data';
import countries from '../util/countries.json';

interface ShipmentValueProps {
  address: Adresse;
}
const ShipmentValue: FC<ShipmentValueProps> = ({address}) => {
  return (
    <div style={{fontWeight: 'bold'}}>
      <p>
        <span style={{fontWeight: 'normal'}}>Adresse : </span>
        {address?.raisonSociale}
      </p>
      <p>{address?.adresse}</p>
      <p>
        {address?.codePostal} {address?.ville},{' '}
        {countries[(address?.isoCountry as keyof typeof countries) ?? 'FR']}
      </p>
    </div>
  );
};

export default memo(ShipmentValue);
