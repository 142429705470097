import {FC, memo} from 'react';
import {useHistory} from 'react-router-dom';
import {
  DeporteBrand,
  Product,
  ProductCategory,
  useCurrentBrand,
  useCurrentCategory,
  useCurrentProduct,
  useCurrentSelectedOptions,
  useCustomMarkFields,
} from '../../contexts/Quote';
import { PRODUCT_CATEGORY } from '../Options/data';
import {productCategories} from './data';
import ProductSelection from './ProductSelection';

const ProductCategories: FC<{parentPath: string}> = ({parentPath}) => {
  const [category, setCategory] = useCurrentCategory();
  const [product, setProduct] = useCurrentProduct();
  const [, setBrand] = useCurrentBrand();
  const [, setSelectedOptions] = useCurrentSelectedOptions();
  const [, setCustomMarkFields] = useCustomMarkFields();
  const history = useHistory();

  const onSelect = (_category: ProductCategory): void => {
    if (_category !== category) {
      setSelectedOptions([]);
      setCategory(_category);

      const products = Array.isArray(_category.products)
        ? _category.products
        : Object.values(_category.products).flat();

      if (product && !products.includes(product)) {
        setProduct((undefined as unknown) as Product);
        setBrand((undefined as unknown) as DeporteBrand);
        setCustomMarkFields(undefined);
      }
    }

    if(_category.name === PRODUCT_CATEGORY.PROTECTION) {
      history.push(`${parentPath}/domain`);
      return;
    }

    history.push(`${parentPath}/products`);
  };

  return (
    <ProductSelection
      products={productCategories}
      onSelect={onSelect}
      value={category || undefined}
      isCategory={true}
    />
  );
};

export default memo(ProductCategories);
