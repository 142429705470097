import React, {FC, memo} from 'react';
import {Redirect, useHistory} from 'react-router-dom';
import {
  BaseQuoteLine,
  getBaseQuoteLineOptions,
  useCurrentLine,
  useLines,
} from '../../contexts/Quote';
import CartLine from './CartLine';

const Cart: FC = () => {
  const [lines, setLines] = useLines();
  const [_, setCurrentLine] = useCurrentLine();
  const history = useHistory();

  const parentPath = lines[0]?.id ? '/orders/edit' : '/quote';

  if (!lines.length) {
    return <Redirect to="/" />;
  }

  const editLine = (line: BaseQuoteLine): void => {
    setCurrentLine(line);
    history.push(`${parentPath}/options`);
  };

  const deleteLine = (deletedLine: BaseQuoteLine): void => {
    setLines(lines.filter((line) => line !== deletedLine));
    setCurrentLine({options: getBaseQuoteLineOptions()});
  };

  return (
    <div css={{margin: '20px 0'}}>
      {lines.map((line, index) => (
        <CartLine
          line={line}
          key={index}
          onEdit={() => editLine(line)}
          onDelete={() => deleteLine(line)}
        />
      ))}
    </div>
  );
};

export default memo(Cart);
