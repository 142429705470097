import {Button, Col, Modal, Row, Select, Spin} from 'antd';
import 'moment/locale/fr';
import React, {FC, memo, useState} from 'react';
import 'react-quill/dist/quill.snow.css';
import {Article, TargetType, TargetTypeLabel} from './index';
import {RESULT_LIMIT, useNewsArticleTypeahead} from './useNewsArticleTypeahead';

const {Option} = Select;

export interface ArticleTargetProps {
  article: Article;
  onArticleChange?: (article: Partial<Article>) => void;
}

const __LOAD_MORE_VALUE = '__LOAD_MORE_VALUE';

const ArticleTarget: FC<ArticleTargetProps> = ({article, onArticleChange}) => {
  const {
    searchInputState: [_, setSearchInput],
    targetTypeState: [targetType, setTargetType],
    searchOffsetState: [searchOffset, setSearchOffset],
    search,
    fetching,
  } = useNewsArticleTypeahead(article);

  const [targets, setTargets] = useState<string[]>(article.targets || []);
  const [modalVisibility, setModalVisibility] = useState(false);

  const handleOnSearch = (search: string): void => {
    setSearchInput(search);
    setSearchOffset(0);
  };

  const handleTargetTypeChange = (targetType: TargetType): void => {
    const changes: Partial<Article> = {targetType};

    setSearchInput('');
    setTargetType(targetType);
    if (targets.length) {
      setTargets([]);
      changes.targets = [];
    }

    onArticleChange && onArticleChange(changes);
  };

  const handleTargetsChange = (_targets: string[]): void => {
    if (_targets.includes(__LOAD_MORE_VALUE)) {
      setSearchOffset(searchOffset + RESULT_LIMIT);
    }

    const targets = _targets.filter((id) => id !== __LOAD_MORE_VALUE);

    setTargets(targets);
    onArticleChange && onArticleChange({targets});
  };

  const targetTypeLabel =
    targetType != null ? TargetTypeLabel[targetType].toLocaleLowerCase() : '';
  const resultLines = search?.result?.lines ?? [];
  const additionalResultCount = (search?.result?.total ?? 0) - searchOffset;

  return (
    <div>
      <p>
        <Button
          type={
            targetType !== TargetType.ALL &&
            (targetType == null || !targets.length)
              ? 'danger'
              : 'default'
          }
          onClick={() => setModalVisibility(true)}
          icon="usergroup-add"
        >
          {targetType === TargetType.ALL
            ? 'Visible par tous les utilisateurs'
            : targetType != null && targets.length
            ? `Visible par ${targets.length} ${targetTypeLabel}`
            : 'Visible par aucun'}
        </Button>
      </p>
      <Modal
        title="Cibles de l'actualité"
        visible={modalVisibility}
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={() => setModalVisibility(false)}
          >
            Confirmer
          </Button>,
        ]}
        closable={false}
      >
        <Row
          type="flex"
          justify="space-between"
          css={{marginBottom: 10}}
          gutter={20}
        >
          <Col css={{lineHeight: '30px'}}>
            <label>Type de cible</label>
          </Col>
          <Col css={{flex: 1, textAlign: 'left'}}>
            <Select
              className="target-type"
              css={{minWidth: 200}}
              placeholder="Visible par aucun"
              value={targetType}
              onChange={handleTargetTypeChange}
            >
              <Option key="none" value={undefined}>
                Visible par aucun
              </Option>
              {Object.entries(TargetTypeLabel).map(([type, label]) => (
                <Option key={type} value={Number(type)}>
                  {label}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
        {targetType != null &&
        [TargetType.CLIENT, TargetType.GROUP].includes(targetType) ? (
          <Row type="flex" justify="space-between" gutter={20}>
            <Col css={{lineHeight: '30px'}}>Cibles {targetTypeLabel}</Col>
            <Col css={{flex: 1}}>
              <Select<string[]>
                mode="multiple"
                css={{width: '100%'}}
                placeholder={`Sélectionnez les ${targetTypeLabel}`}
                onSearch={handleOnSearch}
                value={targets}
                onChange={handleTargetsChange}
                filterOption={false}
                notFoundContent={fetching ? <Spin size="small" /> : null}
              >
                {resultLines.map(({id, label}) => (
                  <Option key={id} value={id}>
                    {label}
                  </Option>
                ))}

                {additionalResultCount > 0 ? (
                  <Option value={__LOAD_MORE_VALUE}>
                    {fetching ? (
                      <Spin size="small" />
                    ) : (
                      `Voir ${Math.min(
                        RESULT_LIMIT,
                        additionalResultCount,
                      )} ${targetTypeLabel} de plus (${additionalResultCount} résultats restants)`
                    )}
                  </Option>
                ) : null}
              </Select>
            </Col>
          </Row>
        ) : null}
      </Modal>
    </div>
  );
};

export default memo(ArticleTarget);
