import {css} from '@emotion/core';
import {Button, Col, DatePicker, Input, Row, Tooltip} from 'antd';
import moment, {Moment} from 'moment';
import 'moment/locale/fr';
import {FC, memo, useState} from 'react';
import 'react-quill/dist/quill.snow.css';
import {DumbUndefinedTuple, UndefinedTuple} from '../../contexts/Quote';
import ConfirmationButton, {
  ConfirmationButtonProps,
} from '../../util/ConfirmationButton';
import NewsArticleContent from './ArticleContent';
import ArticleTarget from './ArticleTarget';
import {Article, TargetType} from './index';

const {RangePicker} = DatePicker;

const articleCss = css`
  background-color: white;
  padding: 10px;
  margin: 10px;

  .title {
    display: inline;
    font-weight: bold;
  }

  .title,
  .content {
    text-align: justify;
    color: #78777f;
  }

  .image-container {
    margin: auto;

    img {
      margin-bottom: 10px;
      max-height: 100px;
      padding: 0 5px;
    }
  }
`;

export interface NewsArticleProps {
  article: Article;
  onArticleSave?: (article: Article) => void;
  onArticleDelete?: (article: Article) => void;
  editRights?: boolean;
}

const NewsArticle: FC<NewsArticleProps> = ({
  article,
  onArticleSave,
  onArticleDelete,
  editRights = false,
}) => {
  const [editMode, setEditMode] = useState(article.editMode || false);

  const [title, setTitle] = useState(article.title);
  const [image, setImage] = useState(article.image);
  const [content, setContent] = useState(article.content);

  const [visibilityRange, setVisibilityRange] = useState<
    DumbUndefinedTuple<Moment>
  >(
    [0, 1].map((index) =>
      article.visibilityDates?.[index]
        ? moment(article.visibilityDates[index])
        : undefined,
    ) as DumbUndefinedTuple<Moment>,
  );

  const [targetType, setTargetType] = useState<TargetType | undefined>(
    article?.targetType ?? undefined,
  );
  const [targets, setTargets] = useState<string[]>([]);

  const handleSave = (): void => {
    setEditMode(!editMode);
    const _article: Article = {
      ...article,
      title,
      image,
      content,
      visibilityDates: (visibilityRange as UndefinedTuple<Moment>).map(
        (date, index) =>
          date &&
          (index === 0 ? date.startOf('day') : date.endOf('day')).format(),
      ) as UndefinedTuple<string>,
      targetType,
      targets,
    };

    /*onArticleChange && onArticleChange(_article);*/
    onArticleSave && onArticleSave(_article);
  };

  const handleCancel = (): void => {
    if (article.id < 0 && (!article.title || !article.content)) {
      return onArticleDelete && onArticleDelete(article);
    }

    setTitle(article.title);
    setImage(article.image);
    setContent(article.content);
    setEditMode(!editMode);
  };

  const visibilityStart = moment(article.visibilityDates?.[0], moment.ISO_8601);

  const deleteArticleButtonProps: ConfirmationButtonProps = {
    callback: () => onArticleDelete && onArticleDelete(article),
    confirmationText: (
      <p>
        Êtes-vous sûr(e) ?<br />
        L&apos;article sera supprimé.
      </p>
    ),
    confirmationButton: 'Oui, supprimer',
    type: 'danger',
    icon: 'delete',
  };

  return (
    <div css={articleCss}>
      <Row type="flex">
        <Col span={24}>
          <Row type="flex" gutter={20}>
            <Col css={{flex: 1}}>
              {editMode ? (
                <Input
                  value={title}
                  onChange={({target: {value}}) => setTitle(value)}
                />
              ) : (
                <div css={{textAlign: 'left'}}>
                  <h3 className="title">{title}</h3>
                  <span style={{float: 'right'}}>
                    <Tooltip title={visibilityStart.format('LLLL')}>
                      <small css={{marginLeft: 5}}>
                        le {visibilityStart.format('DD/MM/YYYY')}
                      </small>
                    </Tooltip>
                  </span>
                </div>
              )}
            </Col>
            {editRights && !editMode ? (
              <Col>
                <Button
                  type="primary"
                  onClick={() => setEditMode(true)}
                  css={{marginRight: '2px'}}
                  icon="edit"
                />
                <ConfirmationButton
                  css={{marginLeft: 2}}
                  {...deleteArticleButtonProps}
                />
              </Col>
            ) : null}
          </Row>
          {editMode ? (
            <Row
              type="flex"
              justify="space-between"
              gutter={20}
              css={{marginTop: 10}}
            >
              <Col>
                <label
                  className={
                    !visibilityRange[0] || !visibilityRange[1]
                      ? 'has-error'
                      : ''
                  }
                >
                  Période de visibilité
                  <RangePicker
                    format="DD/MM/YYYY"
                    css={{margin: '0 5px', maxWidth: 275}}
                    value={visibilityRange}
                    onChange={(dates) =>
                      setVisibilityRange(dates as DumbUndefinedTuple<Moment>)
                    }
                  />
                  {visibilityRange[0] && visibilityRange[1]
                    ? `(environ ${moment
                        .duration(visibilityRange[0].diff(visibilityRange[1]))
                        .humanize()})`
                    : null}
                </label>
              </Col>
              <Col>
                <ArticleTarget
                  article={article}
                  onArticleChange={(_article) => {
                    if (_article.hasOwnProperty('targetType')) {
                      setTargetType(_article.targetType);
                    }
                    if (_article.hasOwnProperty('targets')) {
                      setTargets(_article.targets || []);
                    }
                  }}
                />
              </Col>
            </Row>
          ) : null}
        </Col>
        <NewsArticleContent
          article={{...article, title, image, content}}
          onArticleChange={(_article) => {
            if (_article.hasOwnProperty('content')) {
              setContent(_article.content || '');
            }
            if (_article.hasOwnProperty('image')) {
              setImage(_article.image || '');
            }
          }}
          editMode={editMode}
        />
      </Row>
      {editMode ? (
        <Row type="flex" justify="space-between" css={{marginTop: 10}}>
          <Col>
            {article.id > 0 ? (
              <ConfirmationButton
                css={{marginLeft: 2}}
                {...deleteArticleButtonProps}
              >
                Supprimer l&apos;article
              </ConfirmationButton>
            ) : null}
          </Col>
          <Col>
            <Button
              type="default"
              onClick={handleCancel}
              css={{marginRight: '5px'}}
              icon="close"
            >
              {article.id > 0
                ? 'Annuler les modifications'
                : 'Annuler la création'}
            </Button>
            <Tooltip
              title={
                !visibilityRange[0] || !visibilityRange[1]
                  ? 'Une période de visiblité doit être sélectionnée'
                  : ''
              }
            >
              <Button
                type="primary"
                onClick={editMode ? handleSave : () => setEditMode(true)}
                css={{marginRight: '2px'}}
                icon={article.id > 0 ? 'edit' : 'plus'}
                disabled={!visibilityRange[0] || !visibilityRange[1]}
              >
                {article.id > 0
                  ? 'Sauvegarder les modifications'
                  : "Créer l'article"}
              </Button>
            </Tooltip>
          </Col>
        </Row>
      ) : null}
    </div>
  );
};

export default memo(NewsArticle);
