import {css} from '@emotion/core';
import {FC} from 'react';

const pitchCss = css`
  text-align: center;
  margin: 5px 0;
  height: 125px;
  position: relative;
  top: -15px;

  @media (max-width: 992px) {
    height: 60px;
  }

  .background {
    font-size: 90px;
    color: #dcdce2;
    text-transform: uppercase;

    @media (max-width: 992px) {
      font-size: 70px;
    }
  }

  .foreground {
    position: relative;
    top: -85px;
    z-index: 1;
    font-size: 23px;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 0.1em;
    color: black;

    @media (max-width: 992px) {
      font-size: 20px;
      top: -68px;
    }
  }
`;

const Pitch: FC<{background?: string; foreground?: string}> = ({
  background = 'Bienvenue',
  foreground,
}) => (
  <div css={pitchCss}>
    <div className="background">{background}</div>
    <div className="foreground">{foreground}</div>
  </div>
);

export default Pitch;
